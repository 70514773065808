import React from "react";

import "./EanQRPrintCode.scss";

import QrCode from "qrcode.react";
import sbsmtb from "../../assets/sbsmtb.png";
import {formatCurrency, formatM2H} from "../../utils/util";

const EanQRPrintCode = React.forwardRef(({isArbeid, arbeidMateriaal}, ref) => {

        // const initialState = useMemo(() => {
        //     return {
        //         isArbeid: isArbeid,
        //         arbeidMateriaal: arbeidMateriaal,
        //     };
        // }, [isArbeid, arbeidMateriaal]);

        // const [state, setState] = useState();

        // useEffect(() => {
        //     setState(initialState);
        // }, [initialState]);
        //

        return (
            <div className={"hideprint"} ref={ref}>
                <table className={"label"}>
                    <tbody>
                    <tr>
                        <td className="qrcode">
                            <div>
                                <QrCode fgColor={"#000000"}
                                        size={50}
                                        value={isArbeid ? arbeidMateriaal.code : arbeidMateriaal.eanCode}/>
                            </div>
                        </td>
                        <td className={"spacer"}>&nbsp;</td>
                        <td className={"articledesc"}>
                            <table className={"inner"}>
                                <tbody>
                                <tr>
                                    <td>
                                        <div className={"codeLocation"}>
                                            <div className={"code"}>{isArbeid ? arbeidMateriaal.code : arbeidMateriaal.eanCode}</div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className={"locatie"}>{isArbeid? formatM2H(arbeidMateriaal.tijd)+"u" : (arbeidMateriaal.locatie )}</div>
                                        <div className={"price"}>{isArbeid ?
                                            ("€ " + (formatCurrency(arbeidMateriaal.prijs * arbeidMateriaal.tijd/60))) :
                                            ("€ " + formatCurrency(arbeidMateriaal.prijs))}
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </td>
                        <td className={"logo"}>
                            <img width={"100%"} src={sbsmtb} alt="Stevens bike service"/>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={4} className={"desc"}>
                            {arbeidMateriaal.omschrijving}

                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        )
    }
);

export default EanQRPrintCode;
