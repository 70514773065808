import React, {useEffect, useState} from 'react';

import Snackbar from '@mui/material/Snackbar/index';
import {Alert} from "@mui/material";

const StyledSnackbar = ({message = '', type, resetMessage = () => message = ''}) => {
    const [show, setShow] = useState(false);

    const close = () => {
        setShow(false);
        setTimeout(resetMessage, 10);
    };

    useEffect(() => {
        if (message.length > 0) {
            setShow(true)
        } else {
            setShow(false)
        }
    }, [message, type]);

    return (
        <Snackbar open={show}
                  anchorOrigin = {{'vertical': 'top', horizontal: 'center' }}
                  autoHideDuration={type === 'error' ? 21000 :
                      type === 'warning' ? 8000 : 3000}
                  onClose={close}>
            <Alert
                onClose={close}
                severity={type}
                variant="filled"
                sx={{ width: '100%' }}
            >
                {message}
            </Alert>
        </Snackbar>
    );
};

export default StyledSnackbar;
