
export class Api {
  static successfulResponse = (status) => status >= 200 && status <= 299;
  static basePath = '/api/v1';

  headers = () => ({});
  handleError = (result) => {
  };

  get = async (url, id, qualifier) => {
    // Add options to url
    const urlPath = qualifier && id ? Api.basePath + url + "?" + qualifier + "=" + id  : id ? Api.basePath + url + "/" + id : Api.basePath + url;
      const result = await fetch(urlPath, {
      headers: this.headers()
    });
    // If response is OK
    if (Api.successfulResponse(result.status)) {
      const body = await result.json();
      return body;
    } else {
      console.error("API returned with error " + result.status, result);
      this.handleError(result);
      const errorBody = await result.text();
      throw errorBody;
    }
  };

  get2 = async (url, id, qualifier) => {
    // Add options to url
    const urlPath = qualifier && id ? Api.basePath + url + "/"  + id + "/" + qualifier : Api.basePath + url + "/" + id;
    const result = await fetch(urlPath, {
      headers: this.headers()
    });
    // If response is OK
    if (Api.successfulResponse(result.status)) {
      const body = await result.json();
      return body;
    } else {
      console.error("API returned with error " + result.status, result);
      this.handleError(result);
      const errorBody = await result.text();
      throw errorBody;
    }
  };

  put = async (url, body, qualifier1, qualifier2) => {
    // Add options to url
    const result = await fetch(Api.basePath + url + (qualifier1 ? `/${qualifier1}` : '')+ (qualifier2 ? `/${qualifier2}` : ''), {
      method: "PUT",
      body: JSON.stringify(body),
      headers: {
        ...this.headers(),
        "Content-Type": "application/json"
      }
    });
    // If response is OK
    if (Api.successfulResponse(result.status)) {
      try {
        return await result.json();
      } catch (e) {
        return '';
      }
    } else {
      console.error("API returned with error " + result.status, result);
      this.handleError(result);
      const errorBody = await result.text();
      throw errorBody;
    }
  };

  del = async (url, id, qualifier) => {
    // Add options to url
    const result = await fetch(Api.basePath + url + (id ? `/${id}` : '') + (qualifier ? `/${qualifier}` : ''), {
      method: "DELETE",
      headers: this.headers()
    });
    // If response is OK
    if (Api.successfulResponse(result.status)) {
      try {
        return await result.json();
      } catch (e) {
        return '';
      }
    } else {
      console.error("API returned with error " + result.status, result);
      this.handleError(result);
      throw result;
    }
  };

  post = async (url, body) => {
    // Add options to url
    const result = await fetch(Api.basePath + url, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        ...this.headers(),
        "Content-Type": "application/json"
      }
    });

    // If response is OK
    if (Api.successfulResponse(result.status)) {
      try {
        const body = await result.json();
        return body;
      } catch (e) {
        return '';
      }
    } else {
      console.error("API returned with error " + result.status, result);
      this.handleError(result);
      const errorBody = await result.text();

      throw errorBody;
    }
  };

  getAll = async (url, query, sort, page, size, date, status) => {
    const queryOb = {};
    if (query) queryOb.query = query;
    if (sort) queryOb.sort = sort;
    if (page) queryOb.page = page;
    if (size) queryOb.size = size;
    if (date) queryOb.date = date;
    if (status) queryOb.status = status;

    const qs = new URLSearchParams(queryOb).toString();
    const result = await fetch(Api.basePath + url + (qs ? `?${qs}` : ''), {
      headers: this.headers()
    });

    // If response is OK
    if (Api.successfulResponse(result.status)) {
      return await result.json();
    } else {
      console.error("API returned with error " + result.status, result);
      this.handleError(result);
      throw result;
    }
  };
}

const api = new Api();
export default api;
