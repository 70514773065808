import React from "react";
import {useNavigate} from "react-router-dom";
import "./Document.scss";
import Tooltip from "@mui/material/Tooltip";
import IconButton from '@mui/material/IconButton';
import BuildIcon from '@mui/icons-material/Build';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';

const Document = (({document, removeDocument, context}) => {

    const navigate = useNavigate();


    function navigateToOnderhoud() {
        navigate("/onderhoud/" + document.onderhoudId);
    }

    const getDocumentDownloadLink = () => {
        return window.location.origin + "/api/v1/document/" + document.id;
    }

    return (
        <tr className={"Document OnClick"}>
            <td><a className="inline" href={getDocumentDownloadLink()} download>{document.name}</a></td>
            <td><a className="inline" href={getDocumentDownloadLink()} download>{document.type}</a></td>
            <td className={"alignRight w-20"}>
                <Tooltip title="Download document" placement={"top"}>
                    <a href={getDocumentDownloadLink()} download>
                        <IconButton size="small">
                            <DownloadIcon fontSize="inherit"/>
                        </IconButton>
                    </a>
                </Tooltip>
            </td>
            {context !== "onderhoudDetail" &&
                <td className={"alignRight w-20 bg-white"} onClick={() => navigateToOnderhoud(document.onderhoudId)}>
                    <Tooltip title="Naar onderhoud" placement={"top"}>
                        <IconButton size="small">
                            <BuildIcon fontSize="inherit"/>
                        </IconButton>
                    </Tooltip>
                </td>
            }
            {context === "onderhoudDetail" && <>
                <td className={"alignRight w-20 bg-white"} onClick={() => removeDocument(document.id)}>
                    <Tooltip title="Verwijder document" placement={"top"}>
                        <IconButton size="small">
                            <DeleteIcon fontSize="inherit"/>
                        </IconButton>
                    </Tooltip>
                </td>
                <td className={"alignRight w-20 bg-white"}>&nbsp;</td>
            </>}
        </tr>
    )
});

export default Document;
