import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import "./ArbeidNieuw.scss";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import usePrivateApi from "../../../hooks/usePrivateApi";
import StyledSnackbar from "../../../components/StyledSnackbar/StyledSnackbar";
import TextFieldsIcon from "@mui/icons-material/TextFields";


const ArbeidNieuw = (() => {

    const navigate = useNavigate();
    const returnTo = window.location.search.split("=")[1]

    const api = usePrivateApi();

    let initialArbeid = {
        code: "",
        omschrijving: "",
        prijs: "0",
        tijd: "0",
        btw: "laag"
    }

    const [formFieldValues, setFormFieldValues] = useState(initialArbeid);
    const [isInEditMode, setIsInEditMode] = useState(true);
    const [message, setMessage] = useState({});

    const [arbeid, setArbeid] = useState(initialArbeid);

    useEffect(() => {
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                saveChanges()
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    });

    useEffect(() => {
        const listener = event => {
            if (event.code === "Escape" && isInEditMode) {
                cancelChanges()
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    });

    const toggleEditMode = () => {
        setIsInEditMode(!isInEditMode);
    };

    const changeArbeidState = (key, value) => {
        setFormFieldValues({
            ...formFieldValues,
            [key]: value
        });
    };

    const cancelChanges = () => {
        navigate("/arbeid/");
    };

    const saveChanges = async () => {
        api.updateArbeid(arbeid.id ? arbeid.id : 0, formFieldValues)
            .then(
                (arbeid) => {
                    toggleEditMode();
                    setArbeid(arbeid)
                    setMessage({value: 'Arbeid is opgeslagen', type: 'success'});
                    if (!!returnTo) {
                        addArbeidRegelToOnderhoud(returnTo, arbeid)
                    } else
                        navigate("/arbeid");
                },
                (e) => setMessage({value: `Failed to update arbeid: ${e}`, type: 'error'}))
    };

    const addArbeidRegelToOnderhoud = (onderhoudId, arbeid) => {
        const onderhoudArbeidRegel = {
            "id": null,
            "aantal": 1,
            "omschrijving": arbeid.omschrijving,
            "prijs": arbeid.prijs,
            "totaalPrijs": arbeid.prijs,
            "regelSjabloonId": arbeid.id
        };
        api.updateOnderhoudRegel("arbeid", onderhoudId, 0, onderhoudArbeidRegel)
            .then(
                () => {
                    navigate("/onderhoud/" + returnTo)
                },
                (e) => setMessage({value: `Failed to update arbeid: ${e}`, type: 'error'}))
    };

    const fieldNames = ["code", "omschrijving", "prijs", "tijd", "btw"]

    const capitalize = () => {
        changeArbeidState("omschrijving",
            formFieldValues.omschrijving.charAt(0).toUpperCase() + formFieldValues.omschrijving.slice(1).toLowerCase()
        )
    }

    const n=10;

    return (
        <div className={"ArbeidArbeid"}>
            <h1 className={"capitalize"}>Arbeid</h1>
            <table>
                <tbody>
                <tr>
                    { [...Array(n)].map((e, i) => <td width={1}></td>)}
                </tr>
                <tr>
                    {fieldNames.map(fieldName => (
                        <th className={"capitalize"}
                            colSpan={fieldName === "omschrijving" ? "6" : "1"}
                            key={fieldName}>{fieldName}</th>
                    ))}
                </tr>

                {isInEditMode && (
                    <React.Fragment>
                        <tr className={"arbeid EditMode"}
                        >
                            {fieldNames.map(fieldName => (
                                <td key={fieldName}
                                    colSpan={fieldName === "omschrijving" ? 6 : 1}>
                                    <TextField
                                        autoFocus={fieldName === 'code'}
                                        variant={"standard"}
                                        multiline
                                        value={formFieldValues[fieldName]}
                                        onChange={(e) => changeArbeidState(fieldName, e.target.value)}
                                        type="text"
                                    />
                                </td>
                            ))}
                        </tr>
                        <tr className={"EditMode"}>
                            <td colSpan={10}>
                                <Tooltip title="Annuleer" placement={"top"}>
                                    <IconButton onClick={cancelChanges}>
                                        <CloseIcon/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Bewaar" placement={"top"}>
                                    <IconButton onClick={saveChanges}>
                                        <SaveAltIcon/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Title Case" placement={"top"}>
                                    <IconButton onClick={capitalize}>
                                        <TextFieldsIcon/>
                                    </IconButton>
                                </Tooltip>
                            </td>
                        </tr>
                    </React.Fragment>)}
                </tbody>
            </table>
            <StyledSnackbar message={message.value} type={message.type} resetMessage={() => setMessage({})}/>
            {/*<hr/>*/}
            {/*{"[0] "+JSON.stringify(arbeid)}*/}
            {/*<hr/>*/}
            {/*{"[1] "+JSON.stringify(formFieldValues)}*/}
        </div>
    );
});

export default ArbeidNieuw;
