import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import "./Notitie.scss";
import Tooltip from "@mui/material/Tooltip";
import IconButton from '@mui/material/IconButton';
import BuildIcon from '@mui/icons-material/Build';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {TextareaAutosize} from "@mui/material";

const Notitie = (({notitie}) => {

    const navigate = useNavigate();

    const [isInEditMode, setIsInEditMode] = useState(false);

    function navigateToOnderhoud() {
        navigate("/onderhoud/" + notitie.onderhoudId);
    }

    useEffect(() => {
        const listener = event => {
            if (event.code === "Escape" && isInEditMode) {
                setIsInEditMode(false)
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    });

    const toggleEditMode = () => setIsInEditMode(!isInEditMode)


    return (
        <>
            {!isInEditMode &&
                <tr className={"OnClick"}>
                    <td onClick={toggleEditMode}>
                        {!notitie.text ? "Geen notitie gevonden" : notitie.text.length < 160 ? notitie.text : notitie.text.substring(0, 160) + "\u2026"}
                    </td>
                    <td className={"alignRight"}>
                        <Tooltip title="Notitie volledig bekijken" placement={"top"}>
                            <IconButton size="small" onClick={toggleEditMode}>
                                <VisibilityIcon fontSize="inherit"/>
                            </IconButton>
                        </Tooltip>
                    </td>
                    <td className={"alignRight w-20 bg-white"}>
                        <Tooltip title="Naar Onderhoud" placement={"top"}>
                            <IconButton size="small" onClick={navigateToOnderhoud}>
                                <BuildIcon fontSize="inherit"/>
                            </IconButton>
                        </Tooltip>
                    </td>
                </tr>
            }
            {isInEditMode &&
                <tr>
                    <td colSpan={6} onClick={toggleEditMode}>
                        <TextareaAutosize
                            readOnly={true}
                            style={{width: "100%", marginBottom: "10px"}}
                            value={notitie.text}
                        />
                    </td>
                </tr>
            }
        </>
    );
});

export default Notitie;
