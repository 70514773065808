import moment from 'moment-timezone';

export const states = ['nieuw', 'wacht', 'gepland', 'uitgevoerd', 'gefactureerd'];
export const editableStates = ['nieuw', 'wacht', 'gepland'];


export const renameBoolean = bool => {
    if (bool) return "Yes";
    return "No";
};

export const formatFietsMerk = (fiets) => {
    return !fiets ? null : (
        (fiets.merk ? ` ${fiets.merk} ` : "") +
        (fiets.type ? ` ${fiets.type}` : ""))
};

export const formatKlantName = (klant) => {
    return !klant ? null :
        ((klant.voornaam ? `${klant.voornaam} ` : "") +
            (klant.tvgsl ? `${klant.tvgsl} ` : "") +
            klant.achternaam)
};

export const formatKlantMobile = (klant) => {
    return `${formatKlantName(klant)}<br/>${formatKlantTelefoon(klant)}`;
};

export const formatKlantAdres = (klant) => {
    return !klant ? null :
        ((klant.straat ? `${klant.straat}` : "") +
            (klant.huisnummer ? ` ${klant.huisnummer}` : "") +
            (klant.postcode ? `, ${klant.postcode}` : "") +
            (klant.plaats ? ` ${klant.plaats}` : ""))
};

export const formatKlantPlaats = (klant) => {
    return !klant ? null : ((klant.postcode ? ` ${klant.postcode}` : "") +
        (klant.plaats ? ` ${klant.plaats}` : ""))
};

export const formatKlantTelefoon = (klant) => {
    return !klant ? null : ((klant.telnr ? `[t] ${klant.telnr}` : "") +
        (klant.mobiel ? ` ${klant.mobiel}` : ""))
};
export const formatKlant = (klant) => {
    return !klant ? null :
        ((klant.voornaam ? `${klant.voornaam} ` : "") +
            (klant.tvgsl ? `${klant.tvgsl} ` : "") +
            klant.achternaam +
            (klant.straat ? `, ${klant.straat}` : "") +
            (klant.huisnr ? ` ${klant.huisnr}` : "") +
            (klant.postcode ? `, ${klant.postcode}` : "") +
            (klant.plaats ? ` ${klant.plaats}` : ""))
};

export const formatOmschrijving = (rowType, row) => {
    return rowType === "arbeid" ? formatArbeidOmschrijving(row) : formatMateriaalOmschrijving(row)
};

export const formatArbeidOmschrijving = (arbeid) => {
    return !arbeid ? null : (arbeid.omschrijving ? arbeid.omschrijving : "")
};

export const formatMateriaalOmschrijving = (materiaal) => {
    return !materiaal ? null : (materiaal.omschrijving ? materiaal.omschrijving : "")
};

export const formatOmschrijvingMetPrijs = (rowType, row) => {
    return rowType === "arbeid" ? formatArbeidOmschrijvingMetPrijs(row) : formatMateriaalOmschrijvingMetPrijs(row)
};

export const formatArbeidOmschrijvingMetPrijs = (arbeid) => {
    return !arbeid ? null : formatArbeidOmschrijving(arbeid) +
        (arbeid.prijs && arbeid.tijd ? ` € ${d2c(arbeid.prijs * arbeid.tijd / 60)}` : "")
};

export const formatMateriaalOmschrijvingMetPrijs = (materiaal) => {
    return !materiaal ? null : formatMateriaalOmschrijving(materiaal) +
        (materiaal.prijs ? ` € ${d2c(materiaal.prijs)}` : "") +
        (materiaal.btw ? ` (${materiaal.artikelnummer})` : "")
};
export const truncateText = (row) => {
    if (!row || row.length === 0) return ""
    if (row.length <= 50) return row
    return `${row.slice(0, 50)}...`;
}
export const formatM2H = (minutes) => {
    return isNaN(minutes) ? minutes :
        minutes >= 0 ? `${Math.floor(minutes / 60)}:${(minutes % 60).toString().padStart(2, '0')}` :
            `- ${Math.floor(-minutes / 60)}:${(-minutes % 60).toString().padStart(2, '0')}`;
};

export const prependZero = (value) => parseInt(value) < 10 ? '0' + value : value;

export const formatUtcToDutchDate = utcDateAsString => {
    const momentDate = moment.utc(utcDateAsString).tz('Europe/Amsterdam');

    return momentDate.format('YYYY-MM-DD HH:mm');
};

export const displayDate = dateAsString => {
    const date = new Date(dateAsString);
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    const monthList = ['januari', 'februari', 'maart', 'april', 'mei', 'jumi', 'juli', 'augustus', 'september', 'oktober', 'november', 'december'];

    return `${day} ${monthList[month]} ${year}`;
};

export const makeCommaSeperatedList = list => {
    return list.map(
        (value, index) => value + (index < list.length - 1 ? ", " : "")
    );
};

export function getFromCookie(cookieName) {
    const name = cookieName + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieKeyValues = decodedCookie.split(';');

    const cookieValue = cookieKeyValues
        .find(cookieKeyValue => cookieKeyValue.trimLeft().indexOf(name) === 0);

    return typeof cookieValue !== 'undefined' ? cookieValue.trimLeft().substring(name.length) : '';
}

export function parseJwt(token) {
    try {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    } catch (e) {
        return {}
    }
}

export const convertCamelToTitle = (camel) => {
    let title = camel.replace(/[A-Z]/g, (capital) => ` ${capital}`);

    return title.charAt(0).toUpperCase() + title.substr(1);
};

export const d2c = (prijs) => {
    try {
        return prijs ? prijs.toString().replace(/\./g, ',') : null;

    } catch (e) {
        return null
    }
};

export const formatCurrency = (amount) => {
    let i = parseFloat(amount);
    if (isNaN(i)) {
        i = 0.00;
    }
    let minus = '';
    if (i < 0) {
        minus = '-';
    }
    i = Math.abs(i);
    i = parseInt((i + .005) * 100);
    i = i / 100;
    let s = i.toString();
    if (s.indexOf('.') < 0) {
        s += '.00';
    }
    if (s.indexOf('.') === (s.length - 2)) {
        s += '0';
    }
    s = minus + s;
    return s;
};

export const formatLongDatum = (date) => {
    const datum = new Date(date);
    const options = {weekday: 'long', month: 'long', day: 'numeric'};

    return datum.toLocaleDateString('nl-NL', options);
};
export const formatShortDatum = (date) => {
    const datum = new Date(date);
    const options = {weekday: 'long', month: 'long', day: 'numeric', year: 'numeric'};

    return datum.toLocaleDateString('nl-NL', options);
};

export const totaal = (aantal, prijs) => {
    return (aantal * prijs).toFixed(2)
};

export const percentageToHsl = (percentage) => {
    return 'hsl(' + percentage * 120 + ', 100%, 50%)';
};
