import React, {useEffect, useState} from "react";
import "./KlantKlant.scss";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import usePrivateApi from "../../../hooks/usePrivateApi";
import StyledSnackbar from "../../../components/StyledSnackbar/StyledSnackbar";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import {useNavigate} from "react-router-dom";


const KlantKlant = (({klantid, requestOpenRow}) => {

        const navigate = useNavigate();
        const api = usePrivateApi();
        const newClient = !klantid

        let initialKlant = {
            id: 0, voornaam: "", tvgsl: "", achternaam: "", mobiel: "", email: "",
            postcode: "", huisnummer: "", straat: "", plaats: ""
        }

        const [formFieldValues, setFormFieldValues] = useState(newClient ? initialKlant : {});
        const [isInEditMode, setIsInEditMode] = useState(newClient);
        const [message, setMessage] = useState({});

        const [klant, setKlant] = useState(newClient ? initialKlant : {});
        const [isLoading, setIsLoading] = useState(!newClient);

        useEffect(() => {
            if (!newClient) {
                let isMounted = true;
                setIsLoading(true);
                api.getKlant(klantid).then(
                    data => {
                        if (isMounted) {
                            setKlant(data);
                            setFormFieldValues(data);
                            setIsLoading(false);
                        }
                    },
                    () => {
                        if (isMounted) {
                            setKlant(undefined);
                            setIsLoading(false);
                        }
                    }
                )
            }
        }, [klantid, newClient, api]);

        useEffect(() => {
            const listener = event => {
                if (isInEditMode && (event.code === "Enter" || event.code === "NumpadEnter")) {
                    saveChanges()
                }
            };
            document.addEventListener("keydown", listener);
            return () => {
                document.removeEventListener("keydown", listener);
            };
        });

        useEffect(() => {
            const listener = event => {
                if (event.code === "Escape" && isInEditMode) {
                    cancelChanges()
                }
            };
            document.addEventListener("keydown", listener);
            return () => {
                document.removeEventListener("keydown", listener);
            };
        });

        const toggleEditMode = () => {
            if (requestOpenRow(isInEditMode ? 'close' : 'open')) setIsInEditMode(!isInEditMode);
        };

        const changeKlantKlantState = (key, value) => {
            setFormFieldValues({
                ...formFieldValues,
                [key]: value
            });
        };

        const cancelChanges = () => {
            if (newClient) navigate("/klant/")
            else {
                setFormFieldValues(klant);
                toggleEditMode();
            }
        };

        const saveChanges = async () => {
            api.updateKlant(klant.id, formFieldValues)
                .then(
                    (klant) => {
                        setKlant(klant)
                        setMessage({value: 'Klant is opgeslagen', type: 'success'});
                        if (newClient) navigate("/klant/" + klant.id)
                        else toggleEditMode();
                    },
                    (e) => setMessage({value: `Klant is NIET opgeslagen: ${e}`, type: 'error'}))
        };

        const getStreetByZip = () => {
            var regexpPostcode = /^[1-9][0-9]{3}\s?\s?[a-z]{2}$/i;
            var regexpHuisnummer = /^[1-9][0-9]*/;
            if (regexpPostcode.test(formFieldValues.postcode) && regexpHuisnummer.test(formFieldValues.huisnummer)) {
                fetch(`/api/postcode/free?fq=postcode:${formFieldValues.postcode.replace(/\s/g, '')}&fq=huis_nlt:${formFieldValues.huisnummer}`)
                    .then(response => response.json())
                    .then(data => {
                        if (data.response.numFound === 0) {
                            setMessage({value: 'Onbekende postcode/huisnummer combinatie', type: 'warning'})
                        } else {
                            setFormFieldValues({
                                ...formFieldValues,
                                straat: data.response.docs[0].straatnaam,
                                postcode: data.response.docs[0].postcode,
                                plaats: data.response.docs[0].woonplaatsnaam
                            });
                            setMessage({value: 'Adres opgehaald', type: 'success'})
                        }
                    })
                    .catch(error => {
                        setMessage({value: `Adres niet kunnen ophalen: ${error}`, type: 'error'})
                    });
            } else {
                setMessage({value: 'Ongeldige postcode of huisnummer', type: 'error'})
            }
        }

        const fieldNames = ["voornaam", "tvgsl", "achternaam", "mobiel", "email", "postcode", "huisnummer", "straat", "plaats"]

        return (
            <div className={"KlantKlant"}>
                <h1 className={"capitalize"}>Klant</h1>
                <table>
                    <tbody>
                    <tr>
                        {!isLoading && fieldNames.map(fieldName => (
                            <th className={"capitalize"} key={fieldName}>{fieldName}</th>
                        ))}
                        <th>&nbsp;</th>
                    </tr>

                    {!isLoading && !isInEditMode && (
                        <React.Fragment>
                            <tr className={"klant OnClick"} onClick={toggleEditMode}>
                                {fieldNames.map(fieldName => (
                                    <td key={fieldName}>{klant[fieldName]}</td>
                                ))}
                                <td className={"alignRight"}>
                                    <Tooltip title="Wijzig klant" placement={"top"}>
                                        <IconButton size="small" onClick={toggleEditMode}>
                                            <EditIcon fontSize="inherit"/>
                                        </IconButton>
                                    </Tooltip>
                                </td>
                                <td className={"alignRight bg-white w-20"}>
                                    <Tooltip title="" placement={"top"}>
                                        <IconButton size="small" onClick={toggleEditMode}>
                                        </IconButton>
                                    </Tooltip>
                                </td>

                            </tr>
                        </React.Fragment>
                    )}

                    {!isLoading && isInEditMode && (
                        <React.Fragment>
                            <tr className={"klant EditMode"}>
                                {fieldNames.map(fieldName => (
                                    <td key={fieldName}>
                                        <TextField
                                            autoFocus={fieldName === 'voornaam'}
                                            variant={"standard"}
                                            className="variable-details__fullwidth"
                                            value={formFieldValues[fieldName]}
                                            onChange={(e) => changeKlantKlantState(fieldName, e.target.value)}
                                            type="text"
                                        />
                                    </td>
                                ))}
                            </tr>
                            <tr className={"EditMode"}>
                                <td colSpan={10}>
                                    <Tooltip title="Annuleer" placement={"top"}>
                                        <IconButton onClick={cancelChanges}>
                                            <CloseIcon/>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Bewaar" placement={"top"}>
                                        <IconButton onClick={saveChanges}>
                                            <SaveAltIcon/>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Haal straat op" placement={"top"}>
                                        <IconButton onClick={getStreetByZip}>
                                            <AddLocationIcon/>
                                        </IconButton>
                                    </Tooltip>
                                </td>
                            </tr>
                        </React.Fragment>)}
                    </tbody>
                </table>
                <StyledSnackbar message={message.value} type={message.type} resetMessage={() => setMessage({})}/>
                {/*<hr/>*/}
                {/*{"[0] " + JSON.stringify(klant)}*/}
                {/*<hr/>*/}
                {/*{"[1] " + JSON.stringify(formFieldValues)}*/}
            </div>
        );
    })
;

export default KlantKlant;
