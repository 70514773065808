import React, {useEffect, useState} from "react";
import "./FietsKlant.scss";
import Tooltip from "@mui/material/Tooltip";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import AddLinkIcon from '@mui/icons-material/AddLink';
import PersonIcon from '@mui/icons-material/Person';
import usePrivateApi from "../../../../hooks/usePrivateApi";
import StyledSnackbar from "../../../../components/StyledSnackbar/StyledSnackbar";
import SearchSelect from "../../../../components/SearchSelect/SearchSelect";
import {formatKlant, formatKlantName} from "../../../../utils/util";
import {useNavigate} from "react-router-dom";


const FietsKlant = (({initialKlant, fietsid, requestOpenRow}) => {

    const api = usePrivateApi();
    const navigate = useNavigate();

    const [formFieldValues, setFormFieldValues] = useState({});
    const [isInEditMode, setIsInEditMode] = useState(false);
    const [message, setMessage] = useState({});

    function navigateToKlantDetail() {
        navigate("/klant/" + initialKlant.id);
    }

    useEffect(() => {
        setFormFieldValues({
            klant: initialKlant,
            klantField: formatKlant(initialKlant),
        });
    }, [initialKlant]);

    useEffect(() => {
        const listener = event => {
            if ((event.code === "Enter" || event.code === "NumpadEnter") && isInEditMode) {
                if (isInEditMode) toggleEditMode();
                linkKlant()
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    });

    useEffect(() => {
        const listener = event => {
            if (event.code === "Escape" && isInEditMode) {
                cancelChanges()
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    });

    const selectKlant = (row) => {
        setFormFieldValues({
            ...formFieldValues,
            klant: row ? row : null,
            klantField: row ? formatKlantName(row) : "",
        });
    };

    const handleChange = (key, value) => {
        setFormFieldValues({
            ...formFieldValues,
            [key]: value
        });
    };

    const clearKlant = () => {
        setFormFieldValues({
            klantField: "",
            klant: null,
        });
    };

    const cancelChanges = () => {
        toggleEditMode()
        setFormFieldValues({
            klant: initialKlant,
            klantField: "",
        });
    }

    const linkKlant = async () => {
        api.updateFietsKlant(fietsid, formFieldValues.klant.id)
            .then(
                (data) => {
                    if (data) {
                        setFormFieldValues({
                            klant: data.klant,
                            klantField: formatKlant(data.klant)
                        });
                        toggleEditMode()
                        setMessage({value: 'Fiets is gekoppeld', type: 'success'});
                    } else {
                        setMessage({value: 'Fiets koppelen mislukt', type: 'error'});
                    }
                },
                (e) => setMessage({value: `Fiets koppelen mislukt: ${e}`, type: 'error'}))
    };

    const unlinkKlant = async () => {
        api.removeFietsKlant(fietsid)
            .then(
                () => {
                    setFormFieldValues({klantField: ""})
                    setMessage({value: 'Fiets is ontkoppeld van de klant', type: 'success'});
                },
                (e) => setMessage({value: `Fiets ontkoppelen is mislukt: ${e}`, type: 'error'}))
    };

    const toggleEditMode = () => {
        const allowed = requestOpenRow(isInEditMode ? 'close' : 'open');
        if (allowed) {
            setIsInEditMode(!isInEditMode);
        }
    };

    const klantFieldNames = ["voornaam", "tvgsl", "achternaam", "mobiel", "email", "postcode", "huisnummer", "straat", "plaats"]

    return (
        <div className={"FietsKlant"}>

            <h2 className={"capitalize"}>Klant
                <Tooltip
                    title={formFieldValues.klant ? "Ontkoppel deze fiets van deze klant" : "Koppel een klant aan deze fiets"}
                    placement="top">
                    <IconButton onClick={formFieldValues.klant ? unlinkKlant : toggleEditMode}>
                        {formFieldValues.klant ? <LinkOffIcon/> : <AddLinkIcon/>}
                    </IconButton>
                </Tooltip>
            </h2>
            <table>
                <tbody>
                <React.Fragment>
                    {!isInEditMode &&
                        (formFieldValues.klant ?
                                <>
                                    <tr>
                                        {klantFieldNames.map(fieldName => (
                                            <th className={"capitalize"} key={fieldName}>{fieldName}</th>
                                        ))}
                                    </tr>

                                    <tr className={"fiets OnClick"} onClick={navigateToKlantDetail}>
                                        {klantFieldNames.map(fieldName => (
                                            <td key={fieldName}>{formFieldValues.klant[fieldName]}</td>
                                        ))}
                                        <td className={"alignRight"}>
                                            <Tooltip title="Naar Klant" placement={"top"}>
                                                <IconButton size="small" onClick={navigateToKlantDetail}>
                                                    <PersonIcon fontSize="inherit"/>
                                                </IconButton>
                                            </Tooltip>
                                        </td>
                                        <td className={"alignRight bg-white w-20"}>
                                            <Tooltip title="" placement={"top"}>
                                                <IconButton size="small" onClick={navigateToKlantDetail}>
                                                </IconButton>
                                            </Tooltip>
                                        </td>

                                    </tr>
                                </>
                                :
                                <tr>
                                    <td colSpan={7}>Deze fiets heeft geen eigenaar die klant is.</td>
                                </tr>
                        )}
                    {isInEditMode &&
                        <>
                            <tr>
                                <td className={"search-select search-select__klant"} colSpan={7}>
                                    <SearchSelect
                                        value={formFieldValues.klantField}
                                        initialKey={formFieldValues.klant ? formatKlantName(formFieldValues.klant) : ""}
                                        dataFetchCall={api.getAllKlantenSelect}
                                        selectRow={selectKlant}
                                        updateRow={(row) => handleChange("klantField", row)}
                                        clearRow={clearKlant}
                                        saveRow={linkKlant}
                                        formatSearch={formatKlant}
                                        formatText={formatKlantName}
                                        placeholder={"Klant zoeken"}
                                    />
                                </td>
                            </tr>

                            <tr className={"EditMode"}>
                                <td colSpan={7}>
                                    <Tooltip title="Annuleer" placement={"top"}>
                                        <IconButton onClick={cancelChanges}>
                                            <CloseIcon/>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Bewaar" placement={"top"}>
                                        <IconButton onClick={linkKlant}>
                                            <SaveAltIcon/>
                                        </IconButton>
                                    </Tooltip>
                                </td>
                            </tr>
                        </>
                    }

                </React.Fragment>
                </tbody>
            </table>
            <StyledSnackbar message={message.value} type={message.type} resetMessage={() => setMessage({})}/>
            {/*<hr/>*/
            }
            {/*{"FF " + JSON.stringify(formFieldValues)}*/
            }
            {/*<hr/>*/
            }
            {/*{"initKlant " + JSON.stringify(initialKlant)}*/
            }

        </div>
    )
        ;
});

export default FietsKlant;
