import React, {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";

import "./Experiment.scss";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import usePrivateApi from "../../hooks/usePrivateApi";
import StyledSnackbar from "../../components/StyledSnackbar/StyledSnackbar";


const Experiment = (() => {

    const navigate = useNavigate();

    const api = usePrivateApi();

    let initialKlant = {
        voornaam: "",
        tvgsl: "",
        achternaam: "",
        mobiel: "",
        email: "",
        straat: "",
        huisnummer: "",
        postcode: "",
        plaats: ""
    }

    const [formFieldValues, setFormFieldValues] = useState(initialKlant);
    const [isInEditMode, setIsInEditMode] = useState(true);
    const [message, setMessage] = useState({});

    const [klant, setKlant] = useState(initialKlant);

    useEffect(() => {
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                saveChanges()
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    });

    useEffect(() => {
        const listener = event => {
            if (event.code === "Escape" && isInEditMode) {
                cancelChanges()
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    });

    const toggleEditMode = () => {
        setIsInEditMode(!isInEditMode);
    };

    const changeKlantKlantState = (key, value) => {
        setFormFieldValues({
            ...formFieldValues,
            [key.fieldName]: value
        });
    };

    const cancelChanges = () => {
        navigate("/klant/");
    };

    const getStraatByZip = () => {
        fetch(`http://geodata.nationaalgeoregister.nl/locatieserver/free?fq=postcode:${formFieldValues.postcode}&fq=huis_nlt:${formFieldValues.huisnummer}`)
            .then(response => response.json())
            .then(data => {
                console.log(data)
                setFormFieldValues({
                    ...formFieldValues,
                    straat: data.response.docs[0].straatnaam,
                    plaats: data.response.docs[0].woonplaatsnaam
                });
            })
            .catch(error => {
                console.log(error)
            });
    }

    const saveChanges = async () => {
        api.updateKlant(klant.id, formFieldValues)
            .then(
                (klant) => {
                    toggleEditMode();
                    setKlant(klant)
                    setMessage({value: 'Klant is opgeslagen', type: 'success'});
                    navigate("/klant/" + klant.id);
                },
                (e) => setMessage({value: `Failed to update klant: ${e}`, type: 'error'}))
    };

    const fieldNames = ["voornaam", "tvgsl", "achternaam", "mobiel", "email", "postcode", "huisnummer", "straat", "plaats"]

    return (
        <div className={"KlantKlant"}>
            <h1 className={"capitalize"}>Klant</h1>
            <table>
                <tbody>
                <tr>
                    {fieldNames.map(fieldName => (
                        <th className={"capitalize"} key={fieldName}>{fieldName}</th>
                    ))}
                </tr>

                {isInEditMode && (
                    <React.Fragment>
                        <tr className={"klant EditMode"}>
                            {fieldNames.map(fieldName => (
                                <td key={fieldName}>
                                    <TextField
                                        variant={"standard"}
                                        className="variable-details__fullwidth"
                                        value={formFieldValues[fieldName]}
                                        onChange={(e) => changeKlantKlantState({fieldName}, e.target.value)}
                                        type="text"
                                    />
                                </td>
                            ))}
                        </tr>
                        <tr className={"EditMode"}>
                            <td colSpan={10}>
                                <Tooltip title="Annuleer" placement={"top"}>
                                    <IconButton onClick={cancelChanges}>
                                        <CloseIcon/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Bewaar" placement={"top"}>
                                    <IconButton onClick={saveChanges}>
                                        <SaveAltIcon/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="get straat" placement={"top"}>
                                    <IconButton onClick={getStraatByZip}>
                                        <AddLocationIcon/>
                                    </IconButton>
                                </Tooltip>
                            </td>
                        </tr>
                    </React.Fragment>)}
                </tbody>
            </table>
            <StyledSnackbar message={message.value} type={message.type} resetMessage={() => setMessage({})}/>
            {/*<hr/>*/}
            {/*{"[0] "+JSON.stringify(klant)}*/}
            {/*<hr/>*/}
            {/*{"[1] "+JSON.stringify(formFieldValues)}*/}
        </div>
    );
});

export default Experiment;
