import React from "react";
import "./Onderhoud.scss";
import {formatCurrency, formatFietsMerk, formatKlantName, formatM2H, formatShortDatum} from "../../../utils/util";

import {useNavigate} from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import IconButton from '@mui/material/IconButton';
import BuildIcon from '@mui/icons-material/Build';

const Onderhoud = (({context, onderhoud}) => {

    let navigate = useNavigate();

    const navigateToOnderhoud = () => {
        navigate("/onderhoud/" + onderhoud.id);
    }
    const formatOnderhouStatusOrNumber = () => {
        if (onderhoud.status === "gefactureerd")
            return onderhoud.factuur_nummer
        else return onderhoud.status
    }

    return (
        <tr className="Onderhoud OnClick" onClick={navigateToOnderhoud}>
            {context === 'agenda' &&
                <td>&nbsp;</td>}
            {context !== 'klantOnderhoud' &&
                <td>{onderhoud.voornaam ?
                    formatKlantName({voornaam: onderhoud.voornaam, tvgsl: onderhoud.tvgsl, achternaam: onderhoud.achternaam, }) : "-"}</td>}
            <td>{onderhoud.omschrijving}</td>
            {context !== 'fietsOnderhoud' &&
                <td>{onderhoud.merk  ? formatFietsMerk({merk: onderhoud.merk, type: onderhoud.type}) : "-"}</td>}
            <td>{formatM2H(onderhoud.totaalTijd)}</td>
            {(context === 'klantOnderhoud' || context === 'fietsOnderhoud') &&
                <td>{`€ ${formatCurrency(onderhoud.totaalArbeidKosten + onderhoud.totaalMateriaalNulKosten + onderhoud.totaalMateriaalHoogKosten)}`}</td>}
            {context !== 'agenda' &&
                <td>{onderhoud.datum ? formatShortDatum(onderhoud.datum) : "-"}</td>}
            {context !== 'agenda' &&
                <td>{formatOnderhouStatusOrNumber()}</td>}
            {(context === 'klantOnderhoud' || context === 'fietsOnderhoud') &&
                <>
                    <td className={"alignRight"}>
                        <Tooltip title="Naar Onderhoud" placement={"top"}>
                            <IconButton size="small" onClick={navigateToOnderhoud}>
                                <BuildIcon fontSize="inherit"/>
                            </IconButton>
                        </Tooltip>
                    </td>
                    <td className={"alignRight bg-white w-20"}>
                        <Tooltip title="" placement={"top"}>
                            <IconButton size="small" onClick={navigateToOnderhoud}>
                            </IconButton>
                        </Tooltip>
                    </td>
                </>
            }
        </tr>
    );
});

export default Onderhoud;
