import React, {useState} from "react";
import "./FietsDetail.scss";
import StyledSnackbar from "../../components/StyledSnackbar/StyledSnackbar";
import FietsOnderhoudOverview from "./FietsOnderhoudOverview/FietsOnderhoudOverview"
import {useNavigate, useParams} from "react-router-dom";
import FietsFiets from "./FietsFiets/FietsFiets";
import FietsNotitieDocumentOverview from "./FietsNotitieDocumentOverview/FietsNotitieDocumentOverview";
import usePrivateApi from "../../hooks/usePrivateApi";
import Tooltip from "@mui/material/Tooltip";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

const FietsDetail = (() => {

    const navigate = useNavigate();
    const { id } = useParams()
    const api = usePrivateApi();
    const navigateToFietsen = () => {
        navigate("/fiets");
    }


    const [message, setMessage] = useState({});
    const [isAnyRowOpen, setIsAnyRowOpen] = useState(false);

    const requestOpenRow = (request) => {

        if (request === 'open' && isAnyRowOpen) {
            setMessage({value: "Sluit eerst de open regel.", type: 'warning'});
            return false;
        } else {
            setIsAnyRowOpen(request === 'open');
            return true;
        }
    };

    const deleteFiets = async () => {
        if (window.confirm(`Fiets verwijderen?`)) {
            api.removeFiets(id)
                .then(
                    () => {
                        navigateToFietsen()
                    },
                    error => {
                        setMessage({
                            value: 'Fiets is NIET verwijderd: er is onderhoud aan gekoppeld.',
                            type: 'error'
                        });
                    }
                );
        }
    };

    return (
        <React.Fragment>
            <FietsFiets
                fietsid={id}
                requestOpenRow={requestOpenRow}/>
            <FietsOnderhoudOverview
                fietsid={id}/>
            <FietsNotitieDocumentOverview
                fietsid={id}/>
            <hr/>
            <div className="alignRight">
                <Tooltip title="Verwijder fiets" placement={"right-end"}>
                    <IconButton onClick={deleteFiets}>
                        <DeleteIcon/>
                    </IconButton>
                </Tooltip>
            </div>

            <StyledSnackbar message={message.value} type={message.type} resetMessage={() => setMessage({})}/>
        </React.Fragment>
    );
});

export default FietsDetail;
