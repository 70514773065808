import React, {useEffect, useState} from 'react';
import {TextareaAutosize} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from "@mui/material/Tooltip";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SaveAltIcon from '@mui/icons-material/SaveAlt';


const OnderhoudNotitie = (({initialNotitie, persistOnderhoudNotitie}) => {

    const [notitie, setNotitie] = useState();
    const [isInEditMode, setIsInEditMode] = useState(false);

    useEffect(() => {
        setNotitie(initialNotitie)
    }, [initialNotitie])

    useEffect(() => {
        const listener = event => {
            if (event.code === "Escape" && isInEditMode) {
                cancelChanges()
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    });

    const toggleEditMode = () => setIsInEditMode(!isInEditMode)

    const cancelChanges = () => {
        setNotitie(initialNotitie)
        setIsInEditMode(!isInEditMode)
    }
    const saveForm = () => {
        if (isInEditMode) toggleEditMode();
        persistOnderhoudNotitie(notitie)
    }

    return (
        <div>
            <h2 className={"capitalize"}>Notitie
                {!isInEditMode &&
                    <Tooltip title="Notitie wijzigen" placement="top">
                        <IconButton onClick={toggleEditMode}>
                            <EditIcon/>
                        </IconButton>
                    </Tooltip>}
            </h2>
            <table>
                <tbody>
                {!isInEditMode &&
                    <tr className={"OnClick"} onClick={toggleEditMode}>
                        {!notitie ?
                            <td className={"Italic"}>Geen notitie</td> :
                            <td>{notitie.length < 160 ? notitie : notitie.substring(0, 160) + "\u2026"}</td>}
                        <td className={"alignRight w-20"} onClick={toggleEditMode}>
                            <Tooltip title="Wijzig notitie" placement={"top"}>
                                <IconButton size="small">
                                    <EditIcon fontSize="inherit"/>
                                </IconButton>
                            </Tooltip>
                        </td>
                        <td className={"alignRight w-20 bg-white"}>&nbsp;</td>
                        <td className={"alignRight w-20 bg-white"}>&nbsp;</td>

                    </tr>
                }
                {isInEditMode &&
                    <>
                        <tr>
                            <td colSpan={6}>
                                <TextareaAutosize
                                    minRows={20}
                                    style={{width: "100%", marginBottom: "10px"}}
                                    value={notitie}
                                    onChange={(e) => setNotitie(e.target.value)}
                                />
                            </td>
                        </tr>
                        <tr className={"EditMode"}>
                            <td>
                                <Tooltip title="Annuleer" placement={"top"}>
                                    <IconButton onClick={cancelChanges}>
                                        <CloseIcon/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Bewaar" placement={"top"}>
                                    <IconButton onClick={saveForm}>
                                        <SaveAltIcon/>
                                    </IconButton>
                                </Tooltip>
                            </td>
                        </tr>
                    </>
                }

                </tbody>
            </table>
            {/*{"notitie: "+JSON.stringify(notitie)}*/}
            {/*{"\ninitialNotitie"+JSON.stringify(initialNotitie)}*/}
        </div>

    );
})

export default OnderhoudNotitie;
