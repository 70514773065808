import { Api } from "../Api/Api";
import { ACCESS_TOKEN_KEY, ID_TOKEN_KEY } from "../AuthService/constants";
import { getFromCookie } from "../../utils/util";

export class PrivateApi extends Api {
  constructor() {
    super();
    this.idToken = getFromCookie(ID_TOKEN_KEY);
    this.accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
  }

  handleError = result => {
    switch (result.status) {
      case 401:
        window.location.pathname = "/login";
        break;
      case 403:
        window.location = "/unauthorised";
        break;
      default:
        break;
    }
  };

  login = (idToken, accessToken) => {
    this.idToken = idToken;
    this.accessToken = accessToken;

    document.cookie = `${ID_TOKEN_KEY}=${idToken}; domain=${document.location.hostname}; max-age=${3600}`;
    localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
  };

  logout = () => {
    this.idToken = null;
    this.accessToken = null;
    try {
      localStorage.removeItem(ACCESS_TOKEN_KEY);
      document.cookie = `${ID_TOKEN_KEY}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
    } catch (e) {
      console.error("Cannot clear access token");
    }
  };

  getAllOnderhoud = async (query, sort, page, size, date, status) => this.getAll("/onderhoud", query, sort, page, size, date, status);
  getBestelAdvies = async (query, sort) => this.getAll("/besteladvies", query, sort);
  getOnderhoud = async id => this.get("/onderhoud", id);
  removeOnderhoud = async id => this.del("/onderhoud", id);
  getOnderhoudByKlant = async id => this.get("/onderhoud", id, "klant");
  getOnderhoudByFiets = async id => this.get("/onderhoud", id, "fiets");
  createOnderhoud = async(body) => this.post("/onderhoud", body);
  updateOnderhoudHeader = async(id, body) => this.put(`/onderhoud/${id}`, body);
  updateOnderhoudFiets = async(id, fietsId) => this.put(`/onderhoud/${id}/fiets/${fietsId}`);
  updateOnderhoudNotitie = async(id, body) => this.put(`/onderhoud/${id}/notitie`, body);
  updateOnderhoudRegel = async(rowType, onderhoudId, id, body) => this.put(`/onderhoud/${onderhoudId}/${rowType}/${id}`, body);
  moveOnderhoudRegel = async(rowType, onderhoudId, id, direction) => this.put(`/onderhoud/${onderhoudId}/${rowType}/${id}/${direction}`, {});
  removeOnderhoudRegel = async(rowType, onderhoudId, id) => this.del(`/onderhoud/${onderhoudId}/${rowType}/${id}`);
  getDocumentenByOnderhoud = async id => this.get2("/onderhoud", id, "documenten");
  getFactuurFromOnderhoud = async id => this.get2("/onderhoud", id, "pdffactuur");
  getFactuurExportAsCsv = async id => this.get2("/factuur", id, "export");
  getLastExportedInvoiceNumber = async () => this.get("/factuur/lastExported","","");

  getAllKlanten = async (query, sort, page, size) => this.getAll("/klant", query, sort, page, size);
  getAllKlantenSelect = async (query, sort, page, size) => this.getAll("/klant", query, sort, page, 10);
  getKlant = async id => this.get("/klant", id);
  removeKlant = async(id) => this.del("/klant", id);
  getDocumentenByKlant = async id => this.get2("/klant", id, "documenten");
  getNotitiesByKlant = async id => this.get2("/klant", id, "notities");
  createKlant = async(body) => this.post("/klant", body);
  updateKlant = async(id, body) => this.put(`/klant/${id}`, body);

  getFietsenByKlant = async id => this.get("/fiets", id, "klant");
  updateFiets = async(id, body) => this.put(`/fiets/${id}`, body);
  updateFietsKlant = async(id1, id2) => this.put(`/fiets/${id1}`, '{}', "klant",`${id2}`);
  removeFiets = async(id) => this.del("/fiets", id);
  removeFietsKlant = async(id) => this.del("/fiets", id, "klant");
  getDocumentenByFiets = async id => this.get2("/fiets", id, "documenten");
  getNotitiesByFiets = async id => this.get2("/fiets", id, "notities");
  getAllFietsen = async (query, sort, page, size) => this.getAll("/fiets", query, sort, page, size);
  getFiets = async id => this.get("/fiets", id);

  getAllMateriaal = async (query, sort, page, size) => this.getAll("/materiaal", query, sort, page, size);
  getAllMateriaalSelect = async (query, sort, page, size) => this.getAll("/materiaal", query, sort, page, 10);
  getMateriaal = async id => this.get("/materiaal", id);
  updateMateriaal = async(id, body) => this.put(`/materiaal/${id}`, body);
  updateMateriaalVoorraad = async(id, body) => this.put(`/materiaal/${id}/voorraad`, body);
  removeMateriaal = async(id) => this.del(`/materiaal/${id}`);

  getAgendaPage = async (query, sort, page, size) => this.getAll("/agenda", query, sort, page, size);
  getAllAgenda = async (query, sort, page, size) => this.getAll("/agenda", query, sort, page, 100);
  updateWerkbareTijd = async(datum, body) => this.put(`/agenda/${datum}`, body);

  getAllArbeid = async (query, sort, page, size) => this.getAll("/arbeid", query, sort, page, size);
  getAllArbeidSelect = async (query, sort, page, size) => this.getAll("/arbeid", query, sort, page, 10);
  getArbeid = async id => this.get("/arbeid", id);
  updateArbeid = async(id, body) => this.put(`/arbeid/${id}`, body);

  removeDocument = async id => this.del("/document", id);

  getApk = async id => this.get("/apk", id);
  createApk = async(id) => this.post(`/apk/${id}`, {});
  updateApk = async(id, body) => this.put(`/apk/${id}`, body );
  removeApk = async(id) => this.del(`/apk/${id}`);
}
