import React, {useState, useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";

import "./FietsNieuw.scss";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import usePrivateApi from "../../../hooks/usePrivateApi";
import StyledSnackbar from "../../../components/StyledSnackbar/StyledSnackbar";


const FietsNieuw = (() => {

    const navigate = useNavigate();
    const {id} = useParams()


    const api = usePrivateApi();

    let initialFiets = {
        id: 0,
        merk: "",
        type: "",
        kleur: "",
        bouwjaar: "",
        framenummer: "",
        klantId: id
    }

    const [formFieldValues, setFormFieldValues] = useState(initialFiets);
    const [isInEditMode, setIsInEditMode] = useState(true);
    const [message, setMessage] = useState({});

    const [fiets, setFiets] = useState(initialFiets);

    useEffect(() => {
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                saveChanges()
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    });

    useEffect(() => {
        const listener = event => {
            if (event.code === "Escape" && isInEditMode) {
                cancelChanges()
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    });

    const toggleEditMode = () => {
        setIsInEditMode(!isInEditMode);
    };

    const changeFietsFietsState = (key, value) => {
        setFormFieldValues({
            ...formFieldValues,
            [key.fieldName]: value
        });
    };

    const cancelChanges = () => {
        navigate("/fiets/");
    };

    const saveChanges = async () => {
        api.updateFiets(fiets.id, formFieldValues)
            .then(
                (fiets) => {
                    toggleEditMode();
                    setFiets(fiets)
                    setMessage({value: 'Fiets is opgeslagen', type: 'success'});
                    navigate("/klant/" + id);
                },
                (e) => setMessage({value: `Failed to update fiets: ${e}`, type: 'error'}))
    };

    const fieldNames = ["merk", "type", "kleur", "framenummer", "bouwjaar"]

    return (
        <div className={"FietsFiets"}>
            <h1 className={"capitalize"}>Fiets</h1>
            <table>
                <tbody>
                <tr>
                    {fieldNames.map(fieldName => (
                        <th className={"capitalize"} key={fieldName}>{fieldName}</th>
                    ))}
                </tr>

                {isInEditMode && (
                    <React.Fragment>
                        <tr className={"fiets EditMode"}>
                            {fieldNames.map(fieldName => (
                                <td key={fieldName}>
                                    <TextField
                                        autoFocus={fieldName === "merk"}
                                        variant={"standard"}
                                        className="variable-details__fullwidth"
                                        value={formFieldValues[fieldName]}
                                        onChange={(e) => changeFietsFietsState({fieldName}, e.target.value)}
                                        type="text"
                                    />
                                </td>
                            ))}
                        </tr>
                        <tr className={"EditMode"}>
                            <td colSpan={10}>
                                <Tooltip title="Annuleer" placement={"top"}>
                                    <IconButton onClick={cancelChanges}>
                                        <CloseIcon/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Bewaar" placement={"top"}>
                                    <IconButton onClick={saveChanges}>
                                        <SaveAltIcon/>
                                    </IconButton>
                                </Tooltip>
                            </td>
                        </tr>
                    </React.Fragment>)}
                </tbody>
            </table>
            <StyledSnackbar message={message.value} type={message.type} resetMessage={() => setMessage({})}/>
            {/*<hr/>*/}
            {/*{"[0] "+JSON.stringify(fiets)}*/}
            {/*<hr/>*/}
            {/*{"[1] "+JSON.stringify(formFieldValues)}*/}
        </div>
    );
});

export default FietsNieuw;
