import React, {useEffect, useState} from 'react';
import './OverviewTable.scss';

import {ReactComponent as CaretRight} from "../../Icons/chevron-right.svg";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import {formatCurrency} from "../../utils/util";
import StyledSnackbar from "../StyledSnackbar/StyledSnackbar";

const defaultData = {content: [], page: {}};

const OverviewTable = ({
                           disablePointer,
                           headers,
                           mapDataRow,
                           dataFetchCall,
                           defaultPage = 0,
                           withFilter,
                           withSearch,
                           withTotalValue,
                           isMobile = false,
                           resetOpenRow,
                           withFooter,
                           states,
                           id,
                           deletedId
                       }) => {

    const [data, setData] = useState(defaultData);
    const [isLoading, setIsLoading] = useState(false);
    const [query, setQuery] = useState(null);
    const [debounceQuery, setDebounceQuery] = useState(null);
    const [date, setDate] = useState('all');
    const [status, setStatus] = useState(states ? states.filter(s => s !== "gefactureerd") : []);
    const [sort, setSort] = useState({});
    const [page, setPage] = useState(defaultPage);
    const [message, setMessage] = useState({});

    useEffect(() => {
        const timeout = setTimeout(setDebounceQuery, 500, query);

        return () => clearTimeout(timeout)
    }, [query, setDebounceQuery]);

    useEffect(() => {
        function call() {
            setData(defaultData)
            setIsLoading(true);
            dataFetchCall(debounceQuery,
                sort.name ? `${sort.name}:${sort.order}` : undefined,
                page,
                undefined, // Temporary undefined for sizes
                date ? date : 'all',
                status ? status.join(",") : undefined, id)
                .then(data => {
                    setIsLoading(false);
                    setData(data);
                }, (err) => {
                    setIsLoading(false);
                    setData(defaultData);
                    setMessage({value: `Draait de backend wel? De API geeft status ${err.status}.`, type: 'error'})
                })
        }

        call();
    }, [debounceQuery, sort, page, date, status, dataFetchCall, deletedId, id]);

    function handleSearch(event) {
        setQuery(event.target.value);
        setPage(0);
        if (typeof resetOpenRow === "function") resetOpenRow()
    }

    function handleSetSort(e) {
        const {sortName} = e.target.dataset;

        if (sortName === sort.name && sort.order === 'desc') {
            setSort({});
        } else {
            setSort({
                order: sort.order === 'asc' && sort.name === sortName ? 'desc' : 'asc', name: sortName
            });
        }
    }

    const handleChange = name => event => {
        event.target.checked ? setStatus([...status, name]) : setStatus(status.filter(item => item !== name));
    };

    const handleDateSelectChange = (e) => {
        setDate(e.target.value);
    };
    const handleNextPage = () => setPage(page + 1);
    const handlePreviousPage = () => setPage(page - 1);

    return (<div className={`overview-table ${disablePointer ? '' : 'overview-table__pointer'}`}>
        {withTotalValue && <h3>{`Voorraadwaarde: € ${formatCurrency(data.totalValue)}`}</h3>}
        {withSearch && <TextField
            autoFocus
            variant={"standard"}
            id="outlined-helperText"
            onChange={handleSearch}
            label="Zoek"
            helperText={`Aantal gevonden ${data ? data.page.totalElements : 0}.`}
        />}

        {withFilter && <div>
            <Grid container spacing={3}>
                <Grid item xs={8}>
                    {states && states.map((state, index) => <FormControlLabel
                        control={<Checkbox
                            checked={status.includes(state)}
                            onChange={handleChange(state)}
                            color="primary"
                        />}
                        label={state}
                        key={index}
                    />)}
                </Grid>
                <Grid item xs={4}>
                    <FormControl>

                        <InputLabel id="demo-simple-select-label">Planning</InputLabel>
                        <Select
                            variant={"standard"}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={date}
                            onChange={handleDateSelectChange}
                        >
                            <MenuItem value={"all"}>Alles</MenuItem>
                            <MenuItem value={"lt"}>Eerder</MenuItem>
                            <MenuItem value={"lte"}>Vandaag of eerder</MenuItem>
                            <MenuItem value={"eq"}>Vadaag</MenuItem>
                            <MenuItem value={"gte"}>Vangdaag of later</MenuItem>
                            <MenuItem value={"gt"}>Later</MenuItem>
                            <MenuItem value={"nil"}>Niet gepland</MenuItem>
                        </Select>
                    </FormControl>

                </Grid>
            </Grid>
        </div>}

        <table>
            <thead>
            <tr>
                {headers.map(header => (<th
                    colSpan={header.colSpan ? header.colSpan : undefined}
                    onClick={handleSetSort}
                    key={header.displayName}
                    data-sort-name={header.sortName}
                    className={header.className}
                >
                    {header.displayName}
                    {sort.name === header.sortName && <CaretRight
                        className={`overview-table__order-caret ${sort.order === 'asc' ? 'caret-up' : 'caret-down'}`}/>}
                </th>))}
            </tr>
            </thead>
            <tbody className={isLoading ? 'overview-table__loading' : ''}>
            {data ? data.content.map(mapDataRow) : ""}
            </tbody>
        </table>
        {!isLoading && data.content.length === 0 && (<div>Geen zoekresultaat</div>)}

        {withFooter && data.content.length !== 0 && (
            <div className={`overview-table__page-selection ${isMobile ? "mobile" : ""}`}>
                <div>{data.page.size * page + 1}-{Math.min(data.page.totalElements, data.page.size * (page + 1))} van {data.page.totalElements}</div>
                <div className="overview-table__page-selection__selectors">
                    {page > 0 &&
                        <button className="overview-table__previous" onClick={handlePreviousPage}>
                            <CaretRight className={`caret-left ${isMobile ? "caretMobile" : ""}`}/>
                        </button>}
                    {(page < data.page.totalPages - 1) &&
                        <button className="overview-table__next" onClick={handleNextPage}>
                            <CaretRight className={`${isMobile ? "caretMobile" : ""}`}/>
                        </button>}
                </div>
            </div>)}
        <StyledSnackbar message={message.value} type={message.type} resetMessage={() => setMessage({})}/>

        {/*<span>{`param? ${JSON.stringify(data.totaValue)}`}</span><br/>*/}
    </div>);
};

export default OverviewTable;
