import React, {useEffect, useState} from "react";
import "./OnderhoudApk.scss";
import TextField from "@mui/material/TextField";
import {FormControlLabel, Radio, RadioGroup} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from "@mui/icons-material/Close";
import SaveAltIcon from "@mui/icons-material/SaveAlt";

const OnderhoudApk = ({apk, saveApk, removeApkRow, requestOpenRow, openRow, index}) => {

    const [formFields, setFormFields] = useState(apk);
    const [isInEditMode, setIsInEditMode] = useState(openRow === index);

    useEffect(() => {
        setIsInEditMode(openRow === index)
        }, [openRow, index])

    useEffect(() => {
        const listener = event => {
            if (isInEditMode && (event.code === "Enter" || event.code === "NumpadEnter")) {
                saveChanges()
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    });

    useEffect(() => {
        const listener = event => {
            if (event.code === "Escape" && isInEditMode) {
                cancelChanges()
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    });

    const changeApkRegelState = (key, value) => {
        setFormFields(
            {
                ...formFields,
                [key]: value
            })
    };

    const saveChanges = () => {
        setIsInEditMode(false)
        requestOpenRow('close')
        saveApk(formFields, index)
    }

    const cancelChanges = () => {
        setFormFields(apk)
        setIsInEditMode(false)
        requestOpenRow('close')
    }

    const toggleEditMode = () => {
        if (!isInEditMode && requestOpenRow('open')) setIsInEditMode(!isInEditMode)
    }

    const fieldNames = ["kopTekst", "onderdeelTekst", "apkStatus", "prijs", "opmerking"]
    const getClassName = (fieldName) => {
        switch (fieldName) {
            case 'kopTekst':
                return 'fifteen paddingTop alignLeft';
            case 'onderdeelTekst':
                return 'twenty paddingTop alignLeft';
            case 'apkStatus':
                return 'ten alignLeft';
            case 'opmerking':
                return 'forty alignLeft';
            case 'sortIndex':
                return 'forty alignLeft';
            default:
                return 'ten alignCenter';
        }
    }

    return (
        <React.Fragment>
            {!isInEditMode &&
                <tr className={"apk onClick"} onClick={() => toggleEditMode()}>
                    {fieldNames.map(fieldName => (
                        <td className={getClassName(fieldName)} key={fieldName}>{apk[fieldName]}</td>
                    ))}
                    <td className="alignRight twenty">
                        <Tooltip title="Wijzig APK regel" placement={"right"}>
                            <IconButton size="small">
                                <EditIcon fontSize="inherit"/>
                            </IconButton>
                        </Tooltip>
                    </td>
                    <td className={"alignRight w-20 bg-white"}>&nbsp;</td>
                </tr>}
            {isInEditMode &&
                <>
                    <tr>
                        <td>
                            <TextField
                                variant={"standard"}
                                className={getClassName("kopTekst")}
                                sx={{
                                    '& .MuiInputBase-input': {
                                        'textAlign': 'left',
                                    },
                                }}
                                value={formFields["kopTekst"]}
                                onChange={(e) => changeApkRegelState("kopTekst", e.target.value)}
                                type="text"
                            />
                        </td>
                        <td>
                            <TextField
                                variant={"standard"}
                                className={getClassName("onderdeelTekst")}
                                sx={{
                                    '& .MuiInputBase-input': {
                                        'textAlign': 'left',
                                    },
                                }}
                                value={formFields["onderdeelTekst"]}
                                onChange={(e) => changeApkRegelState("onderdeelTekst", e.target.value)}
                                type="text"
                            />
                        </td>
                        <td>
                            <RadioGroup
                                className={getClassName("apkStatus")}
                                onChange={(e) => changeApkRegelState("apkStatus", e.target.value)}
                                value={formFields["apkStatus"]}
                                name="radio-buttons-group"
                                sx={{
                                    '& .MuiInputBase-input': {
                                        'textAlign': 'center',
                                    },
                                }}

                            >
                                <FormControlLabel value="nvt" control={<Radio/>} label="n.v.t."/>
                                <FormControlLabel value="goed" control={<Radio/>} label="Goed"/>
                                <FormControlLabel value="matig" control={<Radio/>} label="Matig"/>
                                <FormControlLabel value="slecht" control={<Radio/>} label="Slecht"/>
                            </RadioGroup>
                        </td>
                        <td>
                            <TextField
                                variant={"standard"}
                                className={getClassName("prijs")}
                                sx={{
                                    '& .MuiInputBase-input': {
                                        'textAlign': 'right',
                                    },
                                }}
                                value={formFields["prijs"]}
                                onChange={(e) => changeApkRegelState("prijs", e.target.value)}
                                type="text"
                            />
                        </td>
                        <td>
                            <TextField
                                variant={"standard"}
                                className={getClassName("opmerking")}
                                sx={{
                                    '& .MuiInputBase-input': {
                                        'textAlign': 'left',
                                    },
                                }}
                                value={formFields["opmerking"]}
                                onChange={(e) => changeApkRegelState("opmerking", e.target.value)}
                                type="text"
                            />
                        </td>
                        <td>
                            <TextField
                                variant={"standard"}
                                className={getClassName("sortIndex")}
                                sx={{
                                    '& .MuiInputBase-input': {
                                        'textAlign': 'left',
                                    },
                                }}
                                value={formFields["sortIndex"]}
                                onChange={(e) => changeApkRegelState("sortIndex", e.target.value)}
                                type="text"
                            />
                        </td>
                    </tr>
                    <tr className={"EditMode"}>
                        <td colSpan={5}>
                            <Tooltip title="Annuleer" placement={"top"}>
                                <IconButton onClick={cancelChanges}>
                                    <CloseIcon/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Bewaar" placement={"top"}>
                                <IconButton onClick={saveChanges}>
                                    <SaveAltIcon/>
                                </IconButton>
                            </Tooltip>
                        </td>
                        <td className="alignRight">
                            <Tooltip title="Verwijder APK regel" placement={"right"}>
                                <IconButton onClick={() => removeApkRow(index)}>
                                    <DeleteIcon/>
                                </IconButton>
                            </Tooltip>
                        </td>
                    </tr>
                </>
            }
        </React.Fragment>
    )
}

export default OnderhoudApk;
