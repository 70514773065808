import React from "react";
import "./FietsOverview.scss";
import Fiets from "./Fiets/Fiets";
import usePrivateApi from "../../hooks/usePrivateApi";
import OverviewTable from "../../components/OverviewTable/OverviewTable";

const FietsOverview = (() => {

    const api = usePrivateApi();

    return (
        <div className="FietsOverview">
            <h1>Fiets</h1>
            <div className="Fiets">

                <OverviewTable
                    dataFetchCall={api.getAllFietsen}
                    withFilter={false}
                    withSearch={true}
                    withFooter={true}
                    headers={[
                        {displayName: 'Merk/Type', sortName: 'merk'},
                        {displayName: 'Kleur', sortName: 'kleur'},
                        {displayName: 'Bouwjaar', sortName: 'bouwjaar'},
                        {displayName: 'Framenummer', sortName: 'framenummer'},
                        {displayName: 'Klant', sortName: 'klant'}
                    ]}
                    mapDataRow={row => (
                        <Fiets fiets={row} key={row.id}/>
                    )}
                />

            </div>
        </div>
    );
});

export default FietsOverview;
