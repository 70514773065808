import React, {useEffect, useState} from 'react';
import './Header.scss';
import {Link, useLocation, useNavigate} from "react-router-dom";
import sbsmtb from "../../assets/sbs-mtb-blauw.png";
import logo from "../../assets/logo.png";
import Button from "../../components/Button/Button";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

const Navbar = () => {
    let location = useLocation()
    const navigate = useNavigate();

    const [isOpen, setIsOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 768px)');
        setIsMobile(mediaQuery.matches);
        const handleMediaChange = (event) => {
            setIsMobile(event.matches);
        };
        mediaQuery.addEventListener('change', handleMediaChange);
        return () => {
            mediaQuery.removeEventListener('change', handleMediaChange);
        };
    }, []);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };
    const navigateTo = (url) => {
        setIsOpen(false);
        navigate(url)
    }

    return (
        <>
            <div className={"wrapper"}>
                <Link to={"/"} onClick={() => setIsOpen(false)}>
                    <img id="main-logo" src={isMobile ? sbsmtb : logo} alt="Stevens bike service"/>
                </Link>
                <header>

                    <nav className="navbar">
                        <ul className={`nav-links ${isOpen ? 'nav-active' : ''}`}>
                            <Button onClick={() => navigateTo("klant")} active={location.pathname.startsWith("/klant")}>
                                Klant
                            </Button>
                            {!isMobile &&
                                <Button onClick={() => navigateTo("fiets")}
                                        active={location.pathname.startsWith("/fiets")}>
                                    Fiets
                                </Button>}
                            <Button onClick={() => navigateTo("agenda")}
                                    active={location.pathname.startsWith("/agenda")}>
                                Agenda
                            </Button>
                            <Button onClick={() => navigateTo("onderhoud")}
                                    active={location.pathname.startsWith("/onderhoud")}>
                                Onderhoud
                            </Button>
                            <Button onClick={() => navigateTo("materiaal")}
                                    active={location.pathname.startsWith("/materiaal")}>
                                {isMobile ? "Voorraad" : "Materiaal"}
                            </Button>
                            {!isMobile &&
                                <Button onClick={() => navigateTo("arbeid")}
                                        active={location.pathname.startsWith("/arbeid")}>
                                    Arbeid
                                </Button>}
                        </ul>
                        <div className="hamburger" onClick={toggleMenu}>
                            {isOpen ? <CloseIcon/> : <MenuIcon/>}
                        </div>
                    </nav>
                </header>
            </div>
        </>
    );
};

export default Navbar;
