import React, {useState} from "react";
import "./KlantFiets.scss";
import Tooltip from "@mui/material/Tooltip";
import IconButton from '@mui/material/IconButton';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import usePrivateApi from "../../../../hooks/usePrivateApi";
import {useNavigate} from "react-router-dom";
import StyledSnackbar from "../../../../components/StyledSnackbar/StyledSnackbar";
import {BuildAddIcon} from "../../../../Icons/BuildAddIcon";

const KlantFiets = (({fiets}) => {

    const navigate = useNavigate();
    const api = usePrivateApi();

    const [message, setMessage] = useState({});

    const newOnderhoud = () => {
        api.createOnderhoud({
            klantId: fiets.klantId,
            fietsId: fiets.id,
            status: "nieuw"
        })
            .then(
                (data) => {
                    navigate("/onderhoud/" + data.id + "/nieuw");
                },
                (e) => setMessage({value: `Aanmaken nieuw onderhoud is mislukt: ${e}`, type: 'error'}))
    }

    const navigateToFiets = () => navigate("/fiets/" + fiets.id);

    return (
        <tr className={"Fiets OnClick"}>
            <td onClick={newOnderhoud}>{fiets.merk}</td>
            <td onClick={newOnderhoud}>{fiets.type}</td>
            <td onClick={newOnderhoud}>{fiets.kleur}</td>
            <td onClick={newOnderhoud}>{fiets.bouwjaar}</td>
            <td onClick={newOnderhoud}> {fiets.framenummer}</td>
            <td>
                <StyledSnackbar message={message.value} type={message.type} resetMessage={() => setMessage({})}/>

            </td>
            <td className={"alignRight"}>
                <Tooltip title="Nieuw onderhoud" placement={"top"}>
                    <IconButton size="small" onClick={newOnderhoud}>
                        <BuildAddIcon fontSize="inherit"/>
                    </IconButton>
                </Tooltip>
            </td>
            <td className={"alignRight bg-white w-20"}>
                <Tooltip title="Naar Fiets" placement={"top"}>
                    <IconButton size="small" onClick={navigateToFiets}>
                        <DirectionsBikeIcon fontSize="inherit"/>
                    </IconButton>
                </Tooltip>
            </td>
        </tr>
    )
});

export default KlantFiets;
