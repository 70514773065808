import React from 'react';
import './Button.scss';
import { Link } from 'react-router-dom';

function Button({ children, onClick, to, active, color, type, href, download, fullWidth, className }) {
  const buttonClass = `Button ${className ? className : ''} ${active ? ' Active' : ''}${color ? ' ' + color : ''}${fullWidth ? ' full-width' : ''}`;

  if (to) {
    return (
      <Link
        className={buttonClass}
        to={to}
      >
        {children}
      </Link>
    );
  } else if (href) {
    return (
      <a
        className={buttonClass}
        href={href}
        download={download}
      >
        {children}
      </a>
    );
  }
  return (
    <button
      className={buttonClass}
      onClick={onClick}
      type={type}
    >
      {children}
    </button>
  );
}

export default Button;
