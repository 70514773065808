import React, {useState, useEffect} from "react";
import "./KlantFietsOverview.scss";
import KlantFiets from "./KlantFiets/KlantFiets";
import usePrivateApi from "../../../hooks/usePrivateApi";
import StyledSnackbar from "../../../components/StyledSnackbar/StyledSnackbar";
import Tooltip from "@mui/material/Tooltip";
import IconButton from '@mui/material/IconButton';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import {useNavigate} from "react-router-dom";

const KlantFietsOverview = (({klantid, requestOpenRow}) => {

        const navigate = useNavigate();
        const api = usePrivateApi();

        const [message, setMessage] = useState({});

        const [fietsen, setFietsen] = useState({});
        const [isLoading, setIsLoading] = useState(true);

        function navigateToFietsNieuw() {
            navigate(`/fiets/${klantid}/nieuw`);
        }

        useEffect(() => {
            let isMounted = true;
            setIsLoading(true);
            api.getFietsenByKlant(klantid).then(
                data => {
                    if (isMounted) {
                        setFietsen(data.content);
                        setIsLoading(false);
                    }
                },
                () => {
                    if (isMounted) {
                        setFietsen(undefined);
                        setIsLoading(false);
                    }
                }
            );
        }, [klantid, api]);

        const fieldNames = ["Merk", "Type", "Kleur", "Bouwjaar", "Framenummer"]

        return (
            <div className={"KlantFietsOverview"}>
                <h2 className={"capitalize"}>Fietsen
                    <Tooltip title="Nieuwe fiets" placement="top">
                        <IconButton onClick={navigateToFietsNieuw}>
                            <PlaylistAddIcon/>
                        </IconButton>
                    </Tooltip>
                </h2>

                <table>
                    <tbody>
                    <tr>
                        {!isLoading && fieldNames.map(fieldName => (
                            <th className={"capitalize"} key={fieldName}>{fieldName}</th>
                        ))}
                        <th>&nbsp;</th>
                    </tr>

                    {!isLoading && fietsen.length > 0 ? fietsen.map((fiets) =>
                            <KlantFiets
                                fiets={fiets}
                                key={fiets.id}/>) :
                        <tr>
                            <td colSpan={6}>Klant heeft geen fietsen.</td>
                        </tr>}
                    </tbody>
                </table>
                <StyledSnackbar message={message.value} type={message.type} resetMessage={() => setMessage({})}/>
                {/*<hr/>*/}
            </div>
        );
    }
);

export default KlantFietsOverview;
