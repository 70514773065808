import React, {useEffect} from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

function getSteps() {
  return ['Nieuw', 'Wacht', 'Gepland', 'Uitgevoerd', 'Gefactureerd'];
}

const OnderhoudStatusStepper = ({currentState, isStateChangeAllowed}) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  useEffect(() => {
    setActiveStep(currentState)
  }, [currentState]);

  const handleClick = (index) => {
    if (isStateChangeAllowed(index)) {
      index + 1 === steps.length ? setActiveStep(index + 1) : setActiveStep(index)
    }
  };

  return (
    <div>
      <Stepper alternativeLabel activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label} onClick={() => handleClick(index)}>
            <StepLabel >{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

export default OnderhoudStatusStepper;
