import React from "react";

export const BuildAddIcon = () => {
    return (
        <svg height="20px" viewBox="0 0 24 24" width="18px" fill="#888888" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 0h24v24H0z" fill="none"/>
            <path clipRule="evenodd" d="M0 0h24v24H0z" fill="none"/>
            <path
                d="M22.7 19l-9.1-9.1c.9-2.3.4-5-1.5-6.9-2-2-5-2.4-7.4-1.3L9 6 6 9 1.6 4.7C.4 7.1.9 10.1 2.9 12.1c1.9 1.9 4.6 2.4 6.9 1.5l9.1 9.1c.4.4 1 .4 1.4 0l2.3-2.3c.5-.4.5-1.1.1-1.4z"/>
            <path
                d="M 21.005369,3.0268456 V 0.02684564 h -2 V 3.0268456 h -3 v 2 h 3 v 3 h 2 v -3 h 3 v -2 z"/>
        </svg>)
}
