import React, {useEffect, useState} from 'react';
import './SearchSelect.scss';
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from '@mui/material/IconButton';
import Input from "@mui/material/Input";
import ClearIcon from '@mui/icons-material/Clear';
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Tooltip from "@mui/material/Tooltip";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

const defaultData = {content: [], page: {}};

const SearchSelect = ({
                          value,
                          dataFetchCall,
                          formatSearch,
                          formatText,
                          selectRow,
                          clearRow,
                          saveRow,
                          updateRow,
                          placeholder,
                          editOnly,
                          autoFocus = false,
                      }) => {
    const [data, setData] = useState(defaultData);
    const [isLoading, setIsLoading] = useState(false);
    const [query, setQuery] = useState(value ? value : "");
    const [selectedRow, setSelectedRow] = useState(null);

    useEffect(() => {
        setQuery(value ? value : "");
    }, [value] );

    useEffect(() => {
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                if (selectedRow || editOnly) saveRow({
                    ...selectedRow,
                    isSelected: true
                })
                else if (data.content && data.content.length === 1) {
                    saveRow(data.content[0])
                } else {
                    setIsLoading(true);
                    dataFetchCall(query)
                        .then(response => {
                            setIsLoading(false);
                            setData(response);
                            if (response.content && response.content.length === 1) saveRow(response.content[0]);
                        })
                }
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    });

    useEffect(() => {
        if (!editOnly) {
            const timer = setTimeout(() => {
                setIsLoading(true);
                dataFetchCall(query)
                    .then(data => {
                            setIsLoading(false);
                            setData(data);
                            if (autoFocus) document.getElementById("search-field").focus();
                        }
                    );
            }, 300);
            return () => {
                clearTimeout(timer);
            };
        }
    }, [query, dataFetchCall, editOnly, autoFocus]);

    const selectValue = (row) => {
        const formattedRow = formatText(row)
        updateRow(formattedRow);
        setQuery(formattedRow)
        setSelectedRow(row);
        selectRow(row)
    };

    function handleChange(event) {
        const newQuery = event ? (event.target ? event.target.value : "") : "";
        updateRow(newQuery);
        setQuery(newQuery);
    }

    function clearValue() {
        handleChange({});
        clearRow();
    }

    return (
        <div className={"search-select zindex"}>
            <FormControl className={"textfieldmargin"}>
                <Input id={"search-field"}
                       multiline
                       className="md-cell md-cell--bottom"
                       onChange={handleChange}
                       placeholder={placeholder ? placeholder : "Zoeken"}
                       endAdornment={
                           <InputAdornment position="end">
                               <Tooltip title="Leeg zoekveld" placement={"right"}>
                                   <IconButton onClick={clearValue}>
                                       <ClearIcon/>
                                   </IconButton>
                               </Tooltip>
                           </InputAdornment>
                       }
                       value={query}/>
                {!editOnly && (
                    <FormHelperText id="standard-weight-helper-text">
                        {!isLoading ? data.page.totalElements : ("Moment ...")}
                    </FormHelperText>
                )}
            </FormControl>

            {!editOnly && (
                <List className={"selectable-item"} dense={true}>
                    {data.content.map(row => (
                        <ListItem className={"OnClick"} onClick={() => selectValue(row)} key={row.id}>
                            <ListItemText primary={formatSearch(row)}/>
                        </ListItem>))}
                </List>
            )}

            {/*<hr/>*/}
            {/*{"value "+JSON.stringify(autoFocus)}*/}
            {/*<hr/>*/}
            {/*{"query "+ inputRef?.current ? inputRef?.current?.toString() : "blaat"}*/}
            {/*<hr/>*/}
            {/*{"# "+JSON.stringify(data.content ? data.content.length : 'geen content')}*/}
            {/*<hr/>*/}
            {/*/!*{"ir "+JSON.stringify(inputElement.current)}*!/*/}
            {/*<hr/>*/}
            {/*{"[0] "+JSON.stringify(data.content[0])}*/}

        </div>
    );
};

export default SearchSelect;
