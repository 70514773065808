import React from "react";
import { useNavigate } from "react-router-dom";
import "./Klant.scss";
import {formatKlantName, formatKlantAdres, formatKlantTelefoon, } from "../../../utils/util";

const Klant = (({ klant }) => {

    const navigate = useNavigate();

    function navigateToKlantDetail() {
    navigate("/klant/" + klant.id);
  }

  return (
    <tr className={"Klant OnClick"} onClick={navigateToKlantDetail}>
      <td>{formatKlantName(klant)}</td>
      <td>{formatKlantTelefoon(klant)}</td>
      <td>{formatKlantAdres(klant)}</td>
    </tr>
  );
});

export default Klant;
