import React, {useState} from "react";
import "./ArbeidOverview.scss";
import Arbeid from "./Arbeid/Arbeid";
import usePrivateApi from "../../hooks/usePrivateApi";
import OverviewTable from "../../components/OverviewTable/OverviewTable";
import StyledSnackbar from "../../components/StyledSnackbar/StyledSnackbar";
import QrCodeIcon from '@mui/icons-material/QrCode';
import Tooltip from "@mui/material/Tooltip";
import IconButton from '@mui/material/IconButton';
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import {useNavigate} from "react-router-dom";

function ArbeidOverview() {

    const navigate = useNavigate();

    const api = usePrivateApi();
    const [message, setMessage] = useState({});
    const [isAnyRowOpen, setIsAnyRowOpen] = useState(false);
    const [isQr, setIsQr] = useState(false);

    const toggleQr = () => setIsQr(!isQr)
    const navigateToArbeidNieuw = () => {
        navigate("/arbeid/nieuw");
    }

    const requestOpenRow = (request) => {

        if (request === 'open' && isAnyRowOpen) {
            setMessage({value: "Sluit eerst de open regel.", type: 'warning'});
            return false;
        } else {
            setIsAnyRowOpen(request === 'open');
            return true;
        }
    };
    const resetOpenRow = () => {
        setIsAnyRowOpen(false)
        return true
    }

    return (
        <div className="ArbeidOverview">
            <h1>Arbeid
                <Tooltip title="Nieuwe arbeid" placement="top">
                    <IconButton onClick={navigateToArbeidNieuw}>
                        <PlaylistAddIcon/>
                    </IconButton>
                </Tooltip>
                <Tooltip title="QR code" placement="top">
                    <IconButton onClick={toggleQr}>
                        <QrCodeIcon/>
                    </IconButton>
                </Tooltip>
            </h1>
            <div className="Arbeid">

                <OverviewTable
                    dataFetchCall={api.getAllArbeid}
                    withFilter={false}
                    withSearch={true}
                    withFooter={true}
                    resetOpenRow={resetOpenRow}
                    headers={[
                        {displayName: 'Code', className: 'alignLeft',  sortName: 'code', colSpan: 1},
                        {displayName: 'Omschrijving', sortName: 'omschrijving', colSpan: 6},
                        {displayName: 'Tijd', className: 'alignCenter',  sortName: 'tijd', colSpan: 1},
                        {displayName: 'Prijs', className: 'alignCenter', sortName: 'prijs', colSpan: 1},
                        {displayName: 'BTW', className: 'alignCenter', sortName: 'btw', colSpan: 1}
                    ]}
                    mapDataRow={row => (
                        <Arbeid
                            isQr={isQr}
                            requestOpenRow={requestOpenRow}
                            initialArbeid={row}
                            key={row.id}
                            showMessage={setMessage}
                        />
                    )}
                />
            </div>
            <StyledSnackbar message={message.value} type={message.type} resetMessage={() => setMessage({})}/>
        </div>

    );
}

export default ArbeidOverview;
