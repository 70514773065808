import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import "./Fiets.scss";
import {formatFietsMerk, formatKlantName} from "../../../utils/util";
import StyledSnackbar from "../../../components/StyledSnackbar/StyledSnackbar";

const Fiets = (({fiets}) => {

    const navigate = useNavigate();
    const [message, setMessage] = useState({});

    function navigateToFietsDetail() {
        navigate("/fiets/" + fiets.id);
    }

    return (
        <>
            <tr className={"Fiets OnClick"} onClick={navigateToFietsDetail}>
                <td>{formatFietsMerk(fiets)}</td>
                <td>{fiets.kleur}</td>
                <td>{fiets.bouwjaar}</td>
                <td>{fiets.framenummer}</td>
                <td>{formatKlantName(fiets.klant)}</td>
                <td>
                    <StyledSnackbar message={message.value} type={message.type} resetMessage={() => setMessage({})}/>

                </td>
            </tr>
        </>
    );
});

export default Fiets;
