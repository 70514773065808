import React, {useState} from "react";
import "./OnderhoudOverview.scss";
import Onderhoud from "./Onderhoud/Onderhoud";
import usePrivateApi from "../../hooks/usePrivateApi";
import OverviewTable from "../../components/OverviewTable/OverviewTable";
import Tooltip from "@mui/material/Tooltip";
import IconButton from '@mui/material/IconButton';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import {useNavigate} from "react-router-dom";
import StyledSnackbar from "../../components/StyledSnackbar/StyledSnackbar";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const OnderhoudOverview = (({match}) => {

        const navigate = useNavigate();

        const api = usePrivateApi();
        const [message, setMessage] = useState({});
        const [isRedirecting, setIsRedirecting] = useState(false);
        const states = ['schade', 'nieuw', 'wacht', 'gepland', 'uitgevoerd', 'gefactureerd'];


        function navigateToOnderhoudNieuw() {
            setIsRedirecting(true);
            api.createOnderhoud({status: "nieuw"})
                .then(
                    (data) => {
                        navigate("/onderhoud/" + data.id + "/nieuw");
                    },
                    (e) => setMessage({value: `Opslaan is mislukt: ${e}`, type: 'error'}))
        }


        return (
            <div className="OnderhoudOverview">
                <h1>Onderhoud
                    { !isRedirecting &&
                        <Tooltip title="Nieuw onderhoud" placement="top">
                            <IconButton onClick={navigateToOnderhoudNieuw}>
                                <PlaylistAddIcon/>
                            </IconButton>
                        </Tooltip>
                    } <Tooltip title="Export naar administratie" placement="top">
                        <IconButton onClick={() => navigate("/onderhoud/export")}>
                            <FileDownloadIcon/>
                        </IconButton>
                    </Tooltip>
                </h1>
                { isRedirecting &&
                    <p>De kabouters in de backend zijn druk voor je bezig, even geduld dus ...</p>
                }
                <div className="Onderhoud">

                    <OverviewTable
                        dataFetchCall={api.getAllOnderhoud}
                        withFilter={true}
                        withSearch={true}
                        withFooter={true}
                        headers={[
                            {displayName: 'Klant', sortName: 'achternaam'},
                            {displayName: 'Omschrijving', sortName: 'omschrijving'},
                            {displayName: 'Fiets', sortName: 'merk'},
                            {displayName: 'Tijd', sortName: 'tijd'},
                            {displayName: 'Datum', sortName: 'datum'},
                            {displayName: 'Status', sortName: 'status'},
                        ]}
                        states={states}
                        mapDataRow={row => (
                            <Onderhoud context={"overview"} onderhoud={row} key={row.id}/>
                        )}
                    />
                </div>
                <StyledSnackbar message={message.value} type={message.type} resetMessage={() => setMessage({})}/>
            </div>
        );
    })
;

export default OnderhoudOverview;
