import React, {useEffect, useState} from 'react';
import './AgendaSelect.scss';
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from '@mui/material/IconButton';
import Input from "@mui/material/Input";
import ClearIcon from '@mui/icons-material/Clear';
import FormControl from "@mui/material/FormControl";
import Tooltip from "@mui/material/Tooltip";
import Agenda from "../../pages/AgendaOverview/Agenda/Agenda";
import usePrivateApi from "../../hooks/usePrivateApi";

const defaultData = {content: [], page: {}};

const AgendaSelect = ({initialValue, showPastDates, selectDate, placeholder}) => {

    const [data, setData] = useState(defaultData);
    const [value, setValue] = useState(initialValue);

    const api = usePrivateApi();

    useEffect(() => {
        const timer = setTimeout(() => {
            api.getAllAgenda()
                .then(data => {
                    setData(data);
                });
        }, 300);
        return () => {
            clearTimeout(timer);
        };
    }, [api]);

    const selectValue = (row) => {
        setValue(row.datum)
        selectDate(row.datum)
    };

    function clearValue() {
        setValue('');
        selectDate(null);
    }

    return (<div className={"agenda-select zindex"}>
        <FormControl>
            <Input autoFocus
                   readOnly
                   className="md-cell md-cell--bottom"
                   placeholder={placeholder ? placeholder : "Onderhoudsdatum"}
                   value={value}
                   endAdornment={<InputAdornment position="end">
                       <Tooltip title="Verwijder datum" placement={"right"}>
                           <IconButton onClick={clearValue}>
                               <ClearIcon/>
                           </IconButton>
                       </Tooltip>
                   </InputAdornment>}/>
        </FormControl>

        <div className={"agenda"}>
            <table>
                <tbody>
                {data.content.map((row, index) => (
                    (showPastDates || index >= 25) &&
                    <Agenda initialAgenda={row} context={"select"} index={index} key={row.datum}
                            selectRow={() => selectValue(row)}/>))}
                </tbody>
            </table>
        </div>
        {/*<hr/>*/}
        {/*{"value "+JSON.stringify(value)}*/}
        {/*<hr/>*/}
        {/*{"initialValue "+JSON.stringify(initialValue)}*/}

    </div>);
};

export default AgendaSelect;
