import React, {useEffect, useState} from "react";
import "./FietsFiets.scss";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import usePrivateApi from "../../../hooks/usePrivateApi";
import StyledSnackbar from "../../../components/StyledSnackbar/StyledSnackbar";
import FietsKlant from "./FietsKlant/FietsKlant";
import {BuildAddIcon} from "../../../Icons/BuildAddIcon";
import EditIcon from '@mui/icons-material/Edit';
import {useNavigate} from "react-router-dom";


const FietsFiets = (({fietsid, requestOpenRow}) => {

    const navigate = useNavigate();
    const api = usePrivateApi();

    const [formFieldValues, setFormFieldValues] = useState({});
    const [isInEditMode, setIsInEditMode] = useState(false);
    const [message, setMessage] = useState({});

    const [fiets, setFiets] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        let isMounted = true;
        setIsLoading(true);
        api.getFiets(fietsid).then(
            data => {
                if (isMounted) {
                    setFiets(data);
                    setFormFieldValues(data);
                    setIsLoading(false);
                }
            },
            () => {
                if (isMounted) {
                    setFiets(undefined);
                    setIsLoading(false);
                }
            }
        );
    }, [fietsid, api]);

    useEffect(() => {
        const listener = event => {
            if (isInEditMode && (event.code === "Enter" || event.code === "NumpadEnter")) {
                saveChanges()
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    });

    useEffect(() => {
        const listener = event => {
            if (event.code === "Escape" && isInEditMode) {
                cancelChanges()
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    });

    const newOnderhoud = () => {
        if (!fiets?.klant?.id) {
            setMessage({value: "Onderhoud bij een fiets zonder klant is niet mogelijk", type: 'error'})
        } else {
            api.createOnderhoud({
                klantId: fiets.klant.id,
                fietsId: fiets.id,
                status: "nieuw"
            })
                .then(
                    (data) => {
                        navigate("/onderhoud/" + data.id + "/nieuw");
                    },
                    (e) => setMessage({value: `Aanmaken nieuw onderhoud is mislukt: ${e}`, type: 'error'}))
        }
    }

    const toggleEditMode = () => {
        if (requestOpenRow(isInEditMode ? 'close' : 'open')) setIsInEditMode(!isInEditMode);
    };

    const changeFietsKlant = (key, value) => {
        setFormFieldValues({
            ...formFieldValues,
            [key]: value
        });
    };

    const cancelChanges = () => {
        setFormFieldValues(fiets);
        toggleEditMode();
    };

    const saveChanges = async () => {
        api.updateFiets(fiets.id, formFieldValues)
            .then(
                (fiets) => {
                    toggleEditMode();
                    setFiets(fiets)
                    setMessage({value: 'Fiets is opgeslagen', type: 'success'});
                },
                (e) => setMessage({value: `Failed to update fiets: ${e}`, type: 'error'}))
    };

    const fietsFieldNames = ["merk", "type", "kleur", "framenummer", "bouwjaar"]

    return (
        <div className={"FietsKlant"}>
            <h1 className={"capitalize"}>Fiets</h1>
            <table>
                <tbody>
                <tr>
                    {!isLoading && fietsFieldNames.map(fieldName => (
                        <th className={"capitalize"} key={fieldName}>{fieldName}</th>
                    ))}
                    <th>&nbsp;</th>
                </tr>

                {!isLoading && !isInEditMode && (
                    <React.Fragment>
                        <tr className={"fiets OnClick"}>
                            {fietsFieldNames.map(fieldName => (
                                <td key={fieldName} onClick={newOnderhoud}>{fiets[fieldName]}</td>
                            ))}
                            <td className={"alignRight"}>
                                <Tooltip title="Nieuw onderhoud" placement={"top"}>
                                    <IconButton size="small" onClick={newOnderhoud}>
                                        <BuildAddIcon fontSize="inherit"/>
                                    </IconButton>
                                </Tooltip>
                            </td>
                            <td className={"alignRight bg-white w-20"}>
                                <Tooltip title="Fiets bewerken" placement={"top"}>
                                    <IconButton size="small" onClick={toggleEditMode}>
                                        <EditIcon fontSize="inherit"/>
                                    </IconButton>
                                </Tooltip>
                            </td>
                        </tr>
                    </React.Fragment>
                )}

                {!isLoading && isInEditMode && (
                    <React.Fragment>
                        <tr className={"fiets EditMode"}>
                            {fietsFieldNames.map(fieldName => (
                                <td key={fieldName}>
                                    <TextField
                                        variant={"standard"}
                                        className="variable-details__fullwidth"
                                        value={formFieldValues[fieldName]}
                                        onChange={(e) => changeFietsKlant(fieldName, e.target.value)}
                                        type="text"
                                    />
                                </td>
                            ))}
                        </tr>
                        <tr className={"EditMode"}>
                            <td colSpan={10}>
                                <Tooltip title="Annuleer" placement={"top"}>
                                    <IconButton onClick={cancelChanges}>
                                        <CloseIcon/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Bewaar" placement={"top"}>
                                    <IconButton onClick={saveChanges}>
                                        <SaveAltIcon/>
                                    </IconButton>
                                </Tooltip>
                            </td>
                        </tr>
                    </React.Fragment>)}
                </tbody>
            </table>


            <FietsKlant
                initialKlant={fiets.klant}
                fietsid={fiets.id}
                requestOpenRow={requestOpenRow}/>

            <StyledSnackbar message={message.value} type={message.type} resetMessage={() => setMessage({})}/>
            {/*<hr/>*/}
            {/*{"FF " + JSON.stringify(fiets)}*/}
            {/*<hr/>*/}
            {/*{"initialValue "+JSON.stringify(initialValue)}*/}

        </div>
    );
});

export default FietsFiets;
