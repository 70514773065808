import React, {useEffect, useState} from "react";
import "./App.scss";
import {BrowserRouter as Router, Navigate, Route, Routes} from "react-router-dom";
import Header from "./components/Header/Header";
import KlantOverview from "./pages/KlantOverview/KlantOverview";
import KlantDetail from "./pages/KlantDetail/KlantDetail";
import OnderhoudOverview from "./pages/OnderhoudOverview/OnderhoudOverview";
import OnderhoudDetail from "./pages/OnderhoudDetail/OnderhoudDetail";
import MateriaalOverview from "./pages/MateriaalOverview/MateriaalOverview";
import MateriaalOverviewMobile from "./pages/MateriaalOverview/MateriaalOverviewMobile";
import ArbeidOverview from "./pages/ArbeidOverview/ArbeidOverview";
import AgendaOverview from "./pages/AgendaOverview/AgendaOverview";
import FietsOverview from "./pages/FietsOverview/FietsOverview";
import {PrivateApi} from "./services/PrivateApi/PrivateApi";
import {createTheme, ThemeProvider} from "@mui/material";
import MateriaalNieuw from "./pages/MateriaalOverview/MateriaalNieuw/MateriaalNieuw";
import ArbeidNieuw from "./pages/ArbeidOverview/ArbeidNieuw/ArbeidNieuw";
import OnderhoudExport from "./pages/OnderhoudOverview/OnderhoudExport/OnderhoudExport";
import Experiment from "./pages/Experiment/Experiment";
import FietsDetail from "./pages/FietsDetail/FietsDetail";
import FietsNieuw from "./pages/FietsOverview/FietsNieuw/FietsNieuw";
import KlantKlant from "./pages/KlantDetail/KlantKlant/KlantKlant";

const privateApiContext = new PrivateApi();
export const PrivateApiContext = React.createContext({});

const theme = createTheme({
    palette: {
        type: "light",
        contrastThreshold: 3,
        primary: {
            main: "#346beb"
        },
        secondary: {
            main: "#f95d64"
        }
    }
});

function App() {

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 768px)');
        setIsMobile(mediaQuery.matches);
        const handleMediaChange = (event) => {
            setIsMobile(event.matches);
        };
        mediaQuery.addEventListener('change', handleMediaChange);
        return () => {
            mediaQuery.removeEventListener('change', handleMediaChange);
        };
    }, []);

    window.onbeforeunload = function (e) {
        console.log(JSON.stringify(e));
    };

    return (
        <ThemeProvider theme={theme}>
            <PrivateApiContext.Provider value={privateApiContext}>
                <Router>
                    <div className="App">
                        <Header/>

                        <main className="Main">
                            <Routes>
                                <Route path="/onderhoud/export" element={<OnderhoudExport/>}/>
                                <Route path="/onderhoud/:id/:nieuw" element={<OnderhoudDetail/>}/>
                                <Route path="/onderhoud/:id" element={<OnderhoudDetail/>}/>
                                <Route path="/onderhoud" element={<OnderhoudOverview/>}/>

                                <Route path="/klant/nieuw" element={<KlantKlant/>}/>
                                <Route path="/klant/:id" element={<KlantDetail/>}/>
                                <Route path="/klant" element={<KlantOverview/>}/>

                                <Route path="/fiets/:id/nieuw" element={<FietsNieuw/>}/>
                                <Route path="/fiets/:id" element={<FietsDetail/>}/>
                                <Route path="/fiets" element={<FietsOverview/>}/>

                                <Route path="/materiaal/nieuw?returnTo=:returnTo" element={<MateriaalNieuw/>}/>
                                <Route path="/materiaal/nieuw" element={<MateriaalNieuw/>}/>
                                <Route path="/materiaal" element={isMobile ? <MateriaalOverviewMobile/> : <MateriaalOverview/>}/>

                                <Route path="/arbeid/nieuw" element={<ArbeidNieuw/>}/>
                                <Route path="/arbeid" element={<ArbeidOverview/>}/>

                                <Route path="/agenda" element={<AgendaOverview/>}/>

                                <Route path="/experiment" element={<Experiment/>}/>
                                <Route path="/" element={isMobile ?
                                    <Navigate replace to="/materiaal" /> :
                                    <Navigate replace to="/onderhoud" />}/>
                            </Routes>
                        </main>
                    </div>
                </Router>
            </PrivateApiContext.Provider>
        </ThemeProvider>
    );
}

export default App;
