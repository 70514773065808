import React, {useEffect, useState} from "react";
import "./OnderhoudApkOverview.scss";
import OnderhoudApk from "./OnderhoudApk/OnderhoudApk";
import usePrivateApi from "../../../hooks/usePrivateApi";
import StyledSnackbar from "../../../components/StyledSnackbar/StyledSnackbar";
import Tooltip from "@mui/material/Tooltip";
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import IconButton from '@mui/material/IconButton';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';

const OnderhoudApkOverview = (({onderhoudId}) => {

    const api = usePrivateApi();
    const emptyApk = {
        "id": 0,
        "kopTekst": "",
        "onderdeelTekst": "",
        "sortIndex": "00000",
        "apkStatus": "goed",
        "prijs": 0.00,
        "opmerking": ""
    }

    const [message, setMessage] = useState({});

    const [apks, setApks] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [openRow, setOpenRow] = useState(null)
    const [isAnyRowOpen, setIsAnyRowOpen] = useState(false)


    useEffect(() => {
        let isMounted = true;
        setIsLoading(true);
        onderhoudId && api.getApk(onderhoudId).then(data => {
            if (isMounted) {
                setApks(data);
                setIsLoading(false);
            }
        }, () => {
            if (isMounted) {
                setApks([]);
                setIsLoading(false);
            }
        });
    }, [onderhoudId, api]);

    const requestOpenRow = (request) => {
        if (request === 'open' && isAnyRowOpen) {
            setMessage({value: "Sluit eerst de geopende regel ...", type: 'warning'});
            return false;
        } else {
            setIsAnyRowOpen(request === 'open');
            return true;
        }
    };

    const createApk = () => {
        api.createApk(onderhoudId)
            .then((data) => {
                setApks(data);
            }, error => {
                setMessage({
                    value: `Apk is NIET gecreëerd: ${error.status}:${error.statusText}`, type: 'error'
                });
            });
    }

    const removeApk = () => {
        api.removeApk(onderhoudId)
            .then(() => {
                setApks([]);
            }, error => {
                setMessage({
                    value: `Apk is NIET verwijderd: ${error.status}:${error.statusText}`, type: 'error'
                });
            });
    }

    const addApkRow = () => {
        api.updateApk(onderhoudId, [emptyApk, ...apks])
            .then((data) => {
                setApks(data.sort(compare));
            }, error => {
                setMessage({
                    value: `Apk is NIET opgeslagen: ${error.status}:${error.statusText}`, type: 'error'
                });
            });
    }

    const removeApkRow = (index) => {
        api.updateApk(onderhoudId, [...apks.slice(0, index), ...apks.slice(index + 1)])
            .then((data) => {
                setApks(data.sort(compare));
                setIsAnyRowOpen(false)
            }, error => {
                setMessage({
                    value: `Apk is NIET opgeslagen: ${error.status}:${error.statusText}`, type: 'error'
                });
            });
    }

    const saveApk = (apk, index) => {
        api.updateApk(onderhoudId, [...apks.slice(0, index), apk, ...apks.slice(index + 1)])
            .then((data) => {
                setApks(data.sort(compare));
                setOpenRow(index < apks.length - 1 ? index + 1 : null)
                setIsAnyRowOpen(index < apks.length - 1)
            }, error => {
                setMessage({
                    value: `Apk is NIET opgeslagen: ${error.status}:${error.statusText}`, type: 'error'
                });
            });
    }

    const getApkDownloadLink = () => {
        return window.location.origin + "/api/v1/onderhoud/" + onderhoudId + "/pdfapk"
    }

    const getClassName = (fieldName) => {
        switch (fieldName) {
            case 'Kop':
                return 'fifteen alignLeft';
            case 'Onderdeel':
                return 'twenty alignLeft';
            case 'Status':
                return 'ten alignLeft';
            case 'Opmerking':
                return 'thirty alignLeft';
            default:
                return 'ten alignCenter';
        }
    }

    function compare(a, b) {
        if (a.sortIndex < b.sortIndex) { return -1; }
        if (a.sortIndex > b.sortIndex) { return 1; }
        return 0;
    }

    const headerFields = ["Kop", "Onderdeel", "Status", "Prijs", "Opmerking"]

    return (<div className={"KlantApkOverview"}>
        <h2 className={"capitalize"}>Apk
            {apks.length === 0 && <Tooltip title={`Voeg apk toe`} placement="top">
                <IconButton onClick={createApk}>
                    <PlaylistAddIcon/>
                </IconButton>
            </Tooltip>}
            {apks.length > 0 &&
                <>
                    <Tooltip title={`Voeg apkregel toe`} placement="top">
                        <IconButton onClick={addApkRow}>
                            <AddCircleOutlineIcon/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={"Apk rapport downloaden"} placement="top">
                        <IconButton>
                            <a className="inline" href={getApkDownloadLink()} download>
                                <FactCheckOutlinedIcon/>
                            </a>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={"Apk verwijderen"} placement="top">
                        <IconButton onClick={removeApk}>
                            <DeleteIcon/>
                        </IconButton>
                    </Tooltip>
                </>}

        </h2>

        <table>
            <tbody>
            {!isLoading && apks.length > 0 ? (
                <tr>{headerFields.map(fieldName => <th className={getClassName(fieldName)}
                                                       key={fieldName}>{fieldName}</th>)}
                </tr>) : undefined}

            {!isLoading && apks.length > 0 ? apks.map((apk, index) =>
                <OnderhoudApk
                    apk={apk}
                    removeApkRow={removeApkRow}
                    saveApk={saveApk}
                    requestOpenRow={requestOpenRow}
                    openRow={openRow}
                    index={index}
                    key={"apk" + apk.id}/>) : <tr>
                <td colSpan={6} className={"Italic"}>Geen APK rapport.</td>
            </tr>}


            </tbody>
        </table>
        <StyledSnackbar message={message.value} type={message.type} resetMessage={() => setMessage({})}/>
        {/*<hr/>*/}
        {/*{"apks: " + JSON.stringify(openRow)}*/}
        {/*{"apks: " + JSON.stringify(apks.length)}*/}
        {/*{"iiem: " + JSON.stringify(isAnyRowOpen)}*/}
    </div>);
});

export default OnderhoudApkOverview;
