import React, {useState} from "react";
import "./AgendaOverview.scss";
import Agenda from "./Agenda/Agenda";
import usePrivateApi from "../../hooks/usePrivateApi";
import OverviewTable from "../../components/OverviewTable/OverviewTable";
import StyledSnackbar from "../../components/StyledSnackbar/StyledSnackbar";

function AgendaOverview() {

    const api = usePrivateApi();
    const states = ['uitgevoerd', 'gefactureerd'];
    const [message, setMessage] = useState({});

    const saveChanges = async (datum, werkbareTijd) => {
        api.updateWerkbareTijd(datum, werkbareTijd)
            .then((fiets) => {
                setMessage({value: 'Werbare tijd is bijgewerkt', type: 'success'});
            }, (e) => setMessage({value: `Bijwerken werkbare tijd is mislukt: ${e}`, type: 'error'}))
    };

    return (<div className="AgendaOverview">
        <h1>Agenda</h1>
        <div className="Agenda">

            <OverviewTable
                dataFetchCall={api.getAgendaPage}
                defaultPage={1}
                withFilter={false}
                withSearch={false}
                withFooter={true}
                states={states}
                headers={[{
                    displayName: 'Datum', sortName: 'datum', className: 'datum twenty'
                }, {
                    displayName: 'Werkbare tijd', sortName: 'werkbareTijd', className: ' twenty'
                }, {
                    displayName: 'Geplande tijd', sortName: 'geplandeTijd', className: ' twenty'
                }, {
                    displayName: 'Beschikbare tijd', sortName: 'beschikbareTijd', className: ' twenty'
                }, {displayName: 'Onderhoud', sortName: 'onderhoud', className: ' twenty'},]}
                mapDataRow={(row, index) => (<Agenda initialAgenda={row}
                                                     context={"overview"}
                                                     saveChanges={saveChanges}
                                                     index={index}
                                                     key={row.datum}/>)}
            />

        </div>
        <StyledSnackbar message={message.value} type={message.type} resetMessage={() => setMessage({})}/>
    </div>);
}

export default AgendaOverview;
