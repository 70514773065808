import React, {useEffect, useState} from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {TextareaAutosize} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "../../../components/Button/Button"
import usePrivateApi from "../../../hooks/usePrivateApi";
import StyledSnackbar from "../../../components/StyledSnackbar/StyledSnackbar";

const OnderhoudExport = (() => {

    const initialValue = {value: '', copied: false,};
    const api = usePrivateApi();

    const [state, setState] = useState(initialValue);
    const [lastInvoiceNumber, setLastInvoiceNumber] = useState('');
    const [message, setMessage] = useState({});

    useEffect(() => {
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                haalAdminRegelsOp()
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    });

    useEffect(() => {
        let isMounted = true;
        api.getLastExportedInvoiceNumber().then(
            data => {
                if (isMounted) {
                    setLastInvoiceNumber(data)
                }
            },
            (e) => {
                if (isMounted) {
                    setMessage({value: `Laatste factuurnummer niet kunnen ophalen.`, type: 'error'});
                }
            }
        );
    }, [api]);

    const haalAdminRegelsOp = () => {
        setState(initialValue)
        setMessage({})
        if (!lastInvoiceNumber) {
            setMessage({value: 'Eerst het laatst verwerkte factuurnummer invullen', type: 'info'})
        } else if (isNaN(lastInvoiceNumber)) {
            setMessage({value: `${lastInvoiceNumber} is geen geldig factuurnummer`, type: 'warning'})
        } else {
            api.getFactuurExportAsCsv(lastInvoiceNumber)
                .then(data => setState(data))
                .catch(error => {
                    setMessage({
                        value: `Geen factuur gevonden met factuurnummer > ${lastInvoiceNumber}`,
                        type: 'warning'
                    })
                });
        }
    }

    return (
        <div>
            <TextField
                variant={"standard"}
                autoFocus={true}
                className="variable-details__fullwidth"
                value={lastInvoiceNumber}
                onChange={(e) => setLastInvoiceNumber(e.target.value)}
                type="text"
                style={{marginBottom: "10px"}}
                placeholder="Laatste factuurnummer"
            />
            <Button onClick={haalAdminRegelsOp}>Administratieregels ophalen</Button>


            <TextareaAutosize
                minRows={30}
                style={{width: "100%", marginBottom: "10px"}}
                value={state.value}
                onChange={({target: {value}}) => setState({value, copied: false})}/>

            <CopyToClipboard text={state.value}
                             onCopy={() => setState({copied: true})}>
                <Button>{state.copied ? "Gekopieerd." : "Kopieer naar het klembord"}</Button>
            </CopyToClipboard>

            <StyledSnackbar message={message.value} type={message.type} resetMessage={() => setMessage({})}/>

        </div>
    );
})

export default OnderhoudExport;
