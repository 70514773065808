// logInterceptor.js

// Function to send logs to the backend
function logToBackend(level, message) {
    const baseUrl = `${window.location.protocol}//${window.location.host}`;
    fetch(`${baseUrl}/api/v1/log/`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            level: level.toUpperCase(),
            message: message,
        }),
    });
}

// Intercept console.log and console.error
(function() {
    const originalLog = console.log;
    const originalError = console.error;

    console.log = function(...args) {
        logToBackend('info', args.join(' '));
        originalLog.apply(console, args);
    };

    console.error = function(...args) {
        logToBackend('error', args.join(' '));
        originalError.apply(console, args);
    };
})();
