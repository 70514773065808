import React, {useState} from "react";
import "./KlantDetail.scss";
import KlantFietsOverview from "./KlantFietsOverview/KlantFietsOverview";
import KlantKlant from "./KlantKlant/KlantKlant";
import KlantOnderhoudOverview from "./KlantOnderhoudOverview/KlantOnderhoudOverview"
import KlantNotitieDocumentOverview from "./KlantNotitieDocumentOverview/KlantNotitieDocumentOverview";
import {useNavigate, useParams} from "react-router-dom";
import StyledSnackbar from "../../components/StyledSnackbar/StyledSnackbar";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import usePrivateApi from "../../hooks/usePrivateApi";

const KlantDetail = (() => {

    const {id} = useParams()
    const api = usePrivateApi();
    const navigate = useNavigate();

    const [message, setMessage] = useState({});
    const [isAnyRowOpen, setIsAnyRowOpen] = useState(false);
    const navigateToKlanten = () => {
        navigate("/klant");
    }

    const requestOpenRow = (request) => {

        if (request === 'open' && isAnyRowOpen) {
            setMessage({value: "Sluit eerst de open regel.", type: 'warning'});
            return false;
        } else {
            setIsAnyRowOpen(request === 'open');
            return true;
        }
    };
    const deleteKlant = async () => {
        if (window.confirm(`Klant verwijderen?`)) {
            api.removeKlant(id)
                .then(
                    () => {
                        navigateToKlanten()
                    },
                    error => {
                        setMessage({
                            value: 'Klant is NIET verwijderd: er is onderhoud/fiets aan gekoppeld.',
                            type: 'error'
                        });
                    }
                );
        }
    };


    return (
        <React.Fragment>
            <KlantKlant
                klantid={id}
                requestOpenRow={requestOpenRow}/>
            <KlantFietsOverview
                klantid={id}
                requestOpenRow={requestOpenRow}/>
            <KlantOnderhoudOverview
                klantid={id}/>
            <KlantNotitieDocumentOverview
                klantid={id}/>
            <div className="alignRight">
                <Tooltip title="Verwijder klant" placement={"right-end"}>
                    <IconButton onClick={deleteKlant}>
                        <DeleteIcon/>
                    </IconButton>
                </Tooltip>
            </div>

            <StyledSnackbar message={message.value} type={message.type} resetMessage={() => setMessage({})}/>
        </React.Fragment>
    )
        ;
});

export default KlantDetail;
