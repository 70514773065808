import React, {useEffect, useState} from "react";
import "./KlantOverview.scss";
import Klant from "./Klant/Klant";
import usePrivateApi from "../../hooks/usePrivateApi";
import OverviewTable from "../../components/OverviewTable/OverviewTable";
import Tooltip from "@mui/material/Tooltip";
import IconButton from '@mui/material/IconButton';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import {useNavigate} from "react-router-dom";
import KlantMobile from "./Klant/KlantMobile";

const KlantOverview = (({match}) => {

    const navigate = useNavigate();
    const api = usePrivateApi();
    const [isMobile, setIsMobile] = useState(false);
    const [headers, setHeaders] = useState([]);

    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 768px)');
        setIsMobile(mediaQuery.matches);
        const handleMediaChange = (event) => {
            setIsMobile(event.matches);
        };
        mediaQuery.addEventListener('change', handleMediaChange);
        return () => {
            mediaQuery.removeEventListener('change', handleMediaChange);
        };
    }, []);

    useEffect(() => {
        isMobile ?
            setHeaders([
                {displayName: 'Naam/Mobiel', sortName: 'achternaam', className: 'fifty'},
                {displayName: 'Adres', sortName: 'straat', className: 'fifty'}
            ]) :
            setHeaders([
                {displayName: 'Naam', sortName: 'achternaam', className: 'twentyfive'},
                {displayName: 'Mobiel', sortName: 'mobiel', className: 'twentyfive'},
                {displayName: 'Adres', sortName: 'straat', className: 'fifty'}
            ])
    }, [isMobile])

    function navigateToKlantNieuw() {
        navigate("/klant/nieuw");
    }

    return (
        <div className={`KlantenOverview ${isMobile ? "mobile" : ""}`}>
            <h1 className={isMobile ? "h1Mobile" : ""}>Klant
                {!isMobile &&
                    <Tooltip title="Nieuw onderhoud" placement="top">
                        <IconButton onClick={navigateToKlantNieuw}>
                            <PlaylistAddIcon/>
                        </IconButton>
                    </Tooltip>}
            </h1>
            <div className="Klant">

                <OverviewTable
                    dataFetchCall={api.getAllKlanten}
                    withFilter={false}
                    withSearch={true}
                    withFooter={true}
                    headers={headers}
                    mapDataRow={row => (isMobile ?
                            <KlantMobile klant={row} key={row.id}/> :
                            <Klant klant={row} key={row.id}/>
                    )}
                />

            </div>
        </div>
    );
})

export default KlantOverview;
