import React, {useEffect, useState} from "react";
import "./OnderhoudHeader.scss";

import {
    editableStates,
    formatCurrency,
    formatFietsMerk,
    formatKlant,
    formatKlantAdres,
    formatKlantName,
    formatKlantTelefoon,
    formatShortDatum,
    states
} from "../../../utils/util";
import usePrivateApi from "../../../hooks/usePrivateApi";
import StyledSnackbar from "../../../components/StyledSnackbar/StyledSnackbar";
import SearchSelect from "../../../components/SearchSelect/SearchSelect";

import Tooltip from "@mui/material/Tooltip";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import ErrorIcon from '@mui/icons-material/Error';
import TextField from "@mui/material/TextField";
import OnderhoudStatusStepper from "./OnderhoudStateStepper/OnderhoudStatusStepper";
import AgendaSelect from "../../../components/AgendaSelect/AgendaSelect";
import EditIcon from "@mui/icons-material/Edit";
import Checkbox from "@mui/material/Checkbox";

const OnderhoudHeader = (({
                              onderhoud,
                              startInEditMode,
                              changeOnderhoudState,
                              requestOpenRow,
                              isEditAllowed,
                              isAnyRowOpen,
                              persistOnderhoudHeader,
                              persistOnderhoudFiets
                          }) => {

    const api = usePrivateApi();

    const initialFormFieldValues = {
            datum: onderhoud.datum ? onderhoud.datum : "",
            omschrijving: onderhoud.omschrijving ? onderhoud.omschrijving : "",
            status: onderhoud.status,
            isSchade: onderhoud.isSchade,
            klantField: onderhoud.klant ? formatKlantName(onderhoud.klant) : "",
            klant: onderhoud.klant ? onderhoud.klant : null,
            fietsField: onderhoud.fiets ? formatFietsMerk(onderhoud.fiets) : "",
            fiets: onderhoud.fiets ? onderhoud.fiets : null,
        };

    const [formFieldValues, setFormFieldValues] = useState(initialFormFieldValues);
    const [message, setMessage] = useState({});
    const [isInEditMode, setIsInEditMode] = useState(startInEditMode);
    const [isInFietsSelectMode, setIsInFietsSelectMode] = useState(false);
    const [showPastDates, setShowPastDates] = useState(false);
    const [isSchadeRapport, setIsSchadeRapport] = useState();
    const immutableStatusWarning = `Het onderhoud heeft status '${onderhoud.status}' en mag dus niet meer worden aangepast`;

    const getFactuurDownloadLink = () => {
        return window.location.origin + "/api/v1/onderhoud/" + onderhoud.id + "/pdffactuur"
    }

    const getSchadeRapportDownloadLink = () => {
        return window.location.origin + "/api/v1/onderhoud/" + onderhoud.id + "/pdfschaderapport"
    }

    useEffect(() => {
        const listener = event => {
            if ((event.code === "Enter" || event.code === "NumpadEnter") && isInEditMode) {
                persistOnderhoudHeader(formFieldValues)
                if (isInEditMode) toggleEditMode();
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    });

    useEffect(() => {
        const listener = event => {
            if (event.code === "Escape" && isInEditMode) {
                clearOnderhoud()
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    });

    useEffect(() => {
        setFormFieldValues(
            {
                ...onderhoud,
                klantField: onderhoud.klant ? formatKlantName(onderhoud.klant) : "",
                fietsField: onderhoud.fiets ? formatFietsMerk(onderhoud.fiets) : "",
            }
        );
        setIsSchadeRapport(onderhoud.isSchade);
    }, [onderhoud]);

    const handleChange = (key, value) => {
        setFormFieldValues({
            ...formFieldValues,
            [key]: value
        });
    };

    const clearOnderhoud = () => {
        setFormFieldValues(initialFormFieldValues);
        toggleEditMode();
    };

    const persistAndCloseOnderhoud = (formFieldValues) => {
        persistOnderhoudHeader(formFieldValues)
        if (isInEditMode) toggleEditMode();
    }

    const isStateChangeAllowed = (index) => {
        if (isAnyRowOpen) {
            setMessage({value: "Sluit eerst de open regel.", type: 'warning'});
            return false
        }
        if (onderhoud.status === 'gefactureerd') {
            setMessage({value: immutableStatusWarning, type: 'error'});
            return false
        }
        if (editableStates.includes(onderhoud.status) && !editableStates.includes(states[index]) && !isValid(onderhoud)) {
            setMessage({
                value: `Onderhoud moet een klant, een datum, een omschrijving en een arbeid- of materiaalregel hebben voor de status ${states[index]}.`,
                type: 'warning'
            });
            return false
        }
        if ((states[index] !== "nieuw") && (states[index] !== "wacht") && onderhoud.datum == null) {
            setMessage({
                value: `Onderhoud moet een datum hebben voor de status ${states[index]}.`,
                type: 'warning'
            });
            return false
        }
        persistOnderhoudHeader({
            ...formFieldValues,
            status: states[index],
        });
        changeOnderhoudState(states[index])
        return true;
    };

    const isValid = (onderhoud) => {
        return (onderhoud.klant !== null
            && onderhoud.datum !== null
            && onderhoud.omschrijving !== null
            && ((onderhoud.arbeid && onderhoud.arbeid.length)
                || (onderhoud.materiaal && onderhoud.materiaal.length)))
    };

    const selectKlant = (klant) => {
        if (formFieldValues.fiets && formFieldValues.fiets.klant && klant.id !== formFieldValues.fiets.klant.id)
            setFormFieldValues({
                ...formFieldValues,
                klant: klant ? klant : null,
                klantField: klant ? formatKlantName(klant) : "",
                fietsField: "",
                fiets: null,
            }); else
            setFormFieldValues({
                ...formFieldValues,
                klant: klant ? klant : null,
                klantField: klant ? formatKlantName(klant) : "",
            });
    };

    const clearKlant = () => {
        setFormFieldValues({
            ...formFieldValues,
            klantField: "",
            klant: null,
            fietsField: "",
            fiets: null
        });
    };

    const selectFiets = (fiets) => {
        setFormFieldValues({
            ...formFieldValues,
            fiets: fiets ? fiets : "",
            fietsField: fiets ? formatFietsMerk(fiets) : "",
            klant: !formFieldValues.klant && fiets.klant ? fiets.klant : formFieldValues.klant,
            klantField: !formFieldValues.klant && fiets.klant ? formatKlantName(fiets.klant) : formatKlantName(formFieldValues.klant),
        });
        if (fiets.klant && formFieldValues.klant && fiets.klant.id !== formFieldValues.klant.id)
            setMessage({value: "Let op: fiets is van een andere klant!", type: 'warning'})
    };

    const selectAndPersistFiets = (fiets) => {
        persistOnderhoudFiets({...formFieldValues, fiets: fiets});
    }

    const clearFiets = () => {
        setFormFieldValues({
            ...formFieldValues,
            fietsField: "",
            fiets: null
        });
    };

    const toggleEditMode = () => {
        const allowed = requestOpenRow(isInEditMode ? 'close' : 'open');
        if (allowed) {
            setIsInEditMode(!isInEditMode);
        }
    };

    // een andere fiets selecteren mag ook als het onderhoud al is gefactureerd
    const toggleFietsSelectMode = () => {
        setIsInFietsSelectMode(!isInFietsSelectMode);
    };

    const toggleShowPastDates = () => {
        setShowPastDates(!showPastDates);
    };

    const toggleIsSchadeRapport = () => {
        setFormFieldValues({
            ...formFieldValues,
            isSchade: !isSchadeRapport
        });
        setIsSchadeRapport(!isSchadeRapport);
    };

    const downLoadInvoiceText = () => {
        return (onderhoud.status === "gefactureerd" ? `Factuur ${onderhoud.factuur.factuurNummer}` : "Offerte") + " downloaden."
    }

    const getFietsen = async (query, sort, page, size) => {
        if (formFieldValues.klant) {
            return api.getFietsenByKlant(formFieldValues.klant.id)
        } else {
            return api.getAllFietsen(query, sort, page, size)
        }
    };

    return (
        <div className="OnderhoudKlantFietsDatum">
                <h1>
                    {formFieldValues && !formFieldValues.isSchade &&
                        <div className={"paddingBottom"}>
                            <OnderhoudStatusStepper
                                currentState={states.indexOf(onderhoud.status)}
                                isStateChangeAllowed={isStateChangeAllowed}
                            />
                        </div>}

                    <div>
                        <div className={isEditAllowed ? "OnClick" : ""}>
                            {!isSchadeRapport ?
                                (<>
                                    <div onClick={toggleEditMode} className="cut-text">
                                        {onderhoud.omschrijving ? onderhoud.omschrijving : "Onderhoud"}
                                    </div>
                                    <div onClick={toggleEditMode}
                                         className="inline">{onderhoud.datum ? ` op ${formatShortDatum(onderhoud.datum)}` : " (ongepland)"}</div>
                                </>) : (<>
                                    <div onClick={toggleEditMode} className="cut-text">
                                        {onderhoud.omschrijving ? onderhoud.omschrijving : "Schaderapport"}
                                    </div>
                                    <div onClick={toggleEditMode}
                                         className="inline">{onderhoud.datum ? ` van ${formatShortDatum(onderhoud.datum)}` : " (nog in te vullen)"}</div>
                                </>)}

                            {formFieldValues && !formFieldValues.isSchade &&
                                <Tooltip title={downLoadInvoiceText()} placement="top">
                                    <a className="inline" href={getFactuurDownloadLink()} download>
                                        <IconButton>
                                            <ReceiptOutlinedIcon/>
                                        </IconButton>
                                    </a>
                                </Tooltip>}
                            {isSchadeRapport &&
                                <Tooltip title={"Schaderapport downloaden"} placement="top">
                                    <a className="inline" href={getSchadeRapportDownloadLink()} download>
                                        <IconButton>
                                            <ErrorIcon/>
                                        </IconButton>
                                    </a>
                                </Tooltip>}
                        </div>
                    </div>
                </h1>
                <div>
                    <table>
                        <tbody>
                        <tr>
                            <th colSpan={4}><h2>Klant
                                {!onderhoud.klant &&
                                    <Tooltip title="Voeg klant toe" placement="top">
                                        <IconButton onClick={toggleEditMode}>
                                            <ManageSearchIcon/>
                                        </IconButton>
                                    </Tooltip>}
                            </h2>
                                Factuur totaal
                                € {formatCurrency(onderhoud.totaalArbeidKosten + onderhoud.totaalMateriaalNulKosten + onderhoud.totaalMateriaalHoogKosten)}
                            </th>
                        </tr>
                        {!isInEditMode && (onderhoud.klant || onderhoud.fiets) && onderhoud.status !== 'gefactureerd' &&
                            <tr className={isEditAllowed ? "OnClick" : ""} onClick={toggleEditMode}>
                                <td colSpan={3}>{onderhoud.klant ? formatKlantName(onderhoud.klant) : ""}</td>
                                <td colSpan={3}>{onderhoud.fiets ? formatFietsMerk(onderhoud.fiets) : "Geen fiets gekozen."}</td>
                                <td className={"alignRight w-20"}>
                                    <Tooltip title={"Bewerk"} placement={"top"}>
                                        <IconButton size="small" onClick={toggleEditMode}>
                                            {isEditAllowed && <EditIcon fontSize="inherit"/>}
                                        </IconButton>
                                    </Tooltip>
                                </td>
                                <td className={"w-20 bg-white"}>&nbsp;</td>
                                <td className={"w-20 bg-white"}>&nbsp;</td>

                            </tr>}
                        {!isInEditMode && onderhoud.status === 'gefactureerd' &&
                            <tr className={""} onClick={toggleFietsSelectMode}>
                                <td className="thirty">{onderhoud.klant ? formatKlantName(onderhoud.klant) : ""}</td>
                                {!isInFietsSelectMode &&
                                    <td className={"thirty pointer"}>{onderhoud.fiets ? formatFietsMerk(onderhoud.fiets) : "Geen fiets gekozen."}</td>}
                                {isInFietsSelectMode &&
                                    <td className={"thirty search-select"}>
                                        <SearchSelect
                                            value={formFieldValues.fietsField}
                                            initialKey={formFieldValues.fiets ? formFieldValues.fiets.framenummer ? formFieldValues.fiets.framenummer : "" : ""}
                                            dataFetchCall={getFietsen}
                                            selectRow={selectAndPersistFiets}
                                            updateRow={(fiets) => handleChange("fietsField", fiets)}
                                            clearRow={clearFiets}
                                            saveRow={() => persistAndCloseOnderhoud(formFieldValues)}
                                            formatSearch={formatFietsMerk}
                                            formatText={formatFietsMerk}
                                            placeholder={"Fiets zoeken"}
                                        />
                                    </td>}
                            </tr>}
                        {isInEditMode &&
                            <React.Fragment>
                                <tr>
                                    <td className={"twentyfive search-select"} colSpan={2}>
                                        <div className={"table-cell-content"}>

                                        <SearchSelect
                                            autoFocus={true}
                                            value={formFieldValues.klantField}
                                            initialKey={formFieldValues.klant ? formatKlantName(formFieldValues.klant) : ""}
                                            dataFetchCall={api.getAllKlantenSelect}
                                            selectRow={selectKlant}
                                            updateRow={(klant) => handleChange("klantField", klant)}
                                            clearRow={clearKlant}
                                            saveRow={() => persistAndCloseOnderhoud(formFieldValues)}
                                            formatSearch={formatKlant}
                                            formatText={formatKlantName}
                                            placeholder={"Klant zoeken"}
                                        />
                                        </div>
                                    </td>
                                    <td className={"twentyfive search-select"} colSpan={1}>
                                        <div className={"table-cell-content"}>
                                            <SearchSelect
                                                value={formFieldValues.fietsField}
                                                initialKey={formFieldValues.fiets ? formFieldValues.fiets.framenummer ? formFieldValues.fiets.framenummer : "" : ""}
                                                dataFetchCall={getFietsen}
                                                selectRow={selectFiets}
                                                updateRow={(fiets) => handleChange("fietsField", fiets)}
                                                clearRow={clearFiets}
                                                saveRow={() => persistAndCloseOnderhoud(formFieldValues)}
                                                formatSearch={formatFietsMerk}
                                                formatText={formatFietsMerk}
                                                placeholder={"Fiets zoeken"}
                                            />
                                        </div>
                                    </td>
                                    <td className={"twenty alignLeft bg-white"}>
                                        <div className={"table-cell-content"}>
                                            <AgendaSelect
                                                selectDate={(date) => handleChange("datum", date)}
                                                initialValue={formFieldValues.datum}
                                                showPastDates={showPastDates}
                                            />
                                        </div>
                                    </td>
                                    <td colSpan={4} className={"twenty bg-white"}>
                                    <TextField
                                            variant={"standard"}
                                            className={"AlignLeft"}
                                            id="standard-helperText"
                                            placeholder={"Omschrijving"}
                                            value={formFieldValues.omschrijving}
                                            onChange={(event) => handleChange("omschrijving", event.target.value)}
                                        />
                                    </td>
                                </tr>

                                <tr className={"bg-grey"}>
                                    <td colSpan={3}>
                                        <Tooltip title="Annuleer" placement={"top"}>
                                            <IconButton onClick={clearOnderhoud}>
                                                <CloseIcon/>
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Bewaar" placement={"top"}>
                                            <IconButton
                                                onClick={() => persistAndCloseOnderhoud(formFieldValues)}>
                                                <SaveAltIcon/>
                                            </IconButton>
                                        </Tooltip>
                                    </td>
                                    <td colSpan={1} className={"datums-verleden"}>
                                        <Checkbox
                                            checked={showPastDates}
                                            onChange={toggleShowPastDates}
                                            color="primary"
                                        />
                                        datums in het verleden
                                    </td>
                                    <td colSpan={2} className={"is-schaderapport"}>
                                        <Checkbox
                                            checked={isSchadeRapport}
                                            onChange={toggleIsSchadeRapport}
                                            color="primary"
                                        />
                                        dit is een schaderapport
                                    </td>


                                </tr>
                            </React.Fragment>
                        }
                        {!isInEditMode && onderhoud.klant &&
                            <React.Fragment>
                                <tr>
                                    <td
                                        colSpan={"7"}>{onderhoud.klant ? formatKlantAdres(onderhoud.klant) : null}</td>
                                </tr>
                                <tr>
                                    <td colSpan={"7"}>{onderhoud.klant ? formatKlantTelefoon(onderhoud.klant) : null}</td>
                                    {/*<td colSpan={"2"} className={}>{onderhoud.klant ? formatKlantTelefoon(onderhoud.klant) : null}</td>*/}
                                </tr>
                            </React.Fragment>}
                        </tbody>
                    </table>
                </div>
            <StyledSnackbar message={message.value} type={message.type} resetMessage={() => setMessage({})}/>
            {/*<hr/>*/}
            {/*{"formFieldValues " + JSON.stringify(formFieldValues)}*/}
            {/*<hr/>*/}
            {/*{"onderhoud " + JSON.stringify(onderhoud)}*/}
        </div>
    );
});

export default OnderhoudHeader;
