import React, {useEffect, useState} from "react";
import "./OnderhoudDocumentOverview.scss";
import Document from "../../Document/Document";
import usePrivateApi from "../../../hooks/usePrivateApi";
import StyledSnackbar from "../../../components/StyledSnackbar/StyledSnackbar";
import Tooltip from "@mui/material/Tooltip";
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import Uploady, {useItemFinishListener} from "@rpldy/uploady";
import UploadButton from "@rpldy/upload-button";

const OnderhoudDocumentOverview = (({onderhoudId, isEditAllowed}) => {

        const api = usePrivateApi();

        const [message, setMessage] = useState({});

        const [documenten, setDocumenten] = useState([]);
        const [isLoading, setIsLoading] = useState(true);

        useEffect(() => {
            let isMounted = true;
            setIsLoading(true);
            onderhoudId && api.getDocumentenByOnderhoud(onderhoudId).then(
                data => {
                    if (isMounted) {
                        setDocumenten(data);
                        setIsLoading(false);
                    }
                },
                () => {
                    if (isMounted) {
                        setDocumenten([]);
                        setIsLoading(false);
                    }
                }
            );
        }, [onderhoudId, api]);

        const LogProgress = () => {
            useItemFinishListener((item) => {
                const document = [{
                    id: item.uploadResponse.data.id,
                    name: item.file.name,
                    type: item.file.type
                }]
                setDocumenten(documenten.concat(document))
            });
            return null;
        }

        const removeDocument = (id) => {
            if (isEditAllowed || window.confirm(`Document verwijderen?`)) {
                api.removeDocument(id)
                    .then(
                        () => {
                            setDocumenten(documenten.filter(doc => doc.id !== id));
                        },
                        error => {
                            setMessage({
                                value: `Document is NIET verwijderd: ${error.status}:${error.statusText}`,
                                type: 'error'
                            });
                        }
                    );
            }
        }

        return (
            <div className={"KlantDocumentOverview"}>
                <h2 className={"capitalize"}>Documenten
                    <Uploady
                        destination={{url: `${window.location.origin}/api/v1/onderhoud/${onderhoudId}/document`}}>
                        <LogProgress/>
                        <UploadButton className={"MuiButtonBase-root MuiIconButton-root"}>
                            <Tooltip title="Nieuw document" placement="top">
                                <PlaylistAddIcon/>
                            </Tooltip>
                        </UploadButton>
                    </Uploady>
                </h2>

                <table>
                    <tbody>
                    {!isLoading && documenten.length > 0 ? documenten.map((document) =>
                            <Document
                                document={document}
                                removeDocument={removeDocument}
                                context={"onderhoudDetail"}
                                key={document.id}/>) :
                        <tr>
                            <td colSpan={6} className={"Italic"}>Geen documenten</td>
                            <td className={"alignRight w-20 bg-white"}>&nbsp;</td>
                        </tr>}
                    </tbody>
                </table>
                <StyledSnackbar message={message.value} type={message.type} resetMessage={() => setMessage({})}/>
                {/*<hr/>*/}
                {/*{"onderhoudId: "+JSON.stringify(onderhoudId)}*/}

            </div>
        );
    }
);

export default OnderhoudDocumentOverview;
