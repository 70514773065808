import React, {useEffect, useState} from "react";
import "./Materiaal.scss";
import {truncateText} from "../../../utils/util";


const MateriaalMobile = (({initialMateriaal, showMessage}) => {

    const [materiaal, setMateriaal] = useState(initialMateriaal);

    useEffect(() => {
        setMateriaal(initialMateriaal);
    }, [initialMateriaal]);

    const fieldNames =
        ["omschrijving", "voorraad"]

    const columnSpan = (fieldName) => {
        switch (fieldName) {
            case 'omschrijving':
                return 6;
            default:
                return 1;
        }
    }
    const className = (fieldName) => {
        switch (fieldName) {
            case 'omschrijving':
                return 'alignLeft mobile';
            default:
                return 'alignCenter mobile';
        }
    }
    const content = (fieldName) => {
        switch (fieldName) {
            case 'omschrijving':
                return truncateText(materiaal[fieldName])
            default:
                return materiaal[fieldName];
        }
    }

    return (
        <tr className={"materiaal"}>
            {fieldNames.map((fieldName, index) => (
                <td colSpan={columnSpan(fieldName)}
                    className={className(fieldName)}
                    key={fieldName}>
                    {content(fieldName)}</td>
            ))}
        </tr>
    );
});

export default MateriaalMobile;
