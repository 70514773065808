import React from "react";
import "./KlantOnderhoudOverview.scss";
import Onderhoud from "../../OnderhoudOverview/Onderhoud/Onderhoud";
import usePrivateApi from "../../../hooks/usePrivateApi";
import OverviewTable from "../../../components/OverviewTable/OverviewTable";

const KlantOnderhoudOverview = (({klantid, navigate}) => {

    const api = usePrivateApi();

    return (
      <div className="OnderhoudOverview">
        <h2>Onderhoud historie
        </h2>
        <div className="Onderhoud">

          <OverviewTable
            dataFetchCall={() => api.getOnderhoudByKlant(klantid)}
            withFilter={false}
            withSearch={false}
            headers={[
              {displayName: 'Omschrijving', sortName: 'omschrijving'},
              {displayName: 'Fiets', sortName: 'fiets.merk'},
              {displayName: 'Tijd', sortName: 'totaalTijd'},
              {displayName: 'Totaal', sortName: 'totaalBedrag'},
              {displayName: 'Datum', sortName: 'datum'},
              {displayName: 'Status', sortName: 'status'},
            ]}
            mapDataRow={row => (
              <Onderhoud context={"klantOnderhoud"} onderhoud={row} key={row.id}/>
            )}
          />
        </div>
      </div>
    );
  })

export default KlantOnderhoudOverview;
