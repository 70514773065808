import React from "react";
import "./FietsNotitieDocumentOverview.scss";
import usePrivateApi from "../../../hooks/usePrivateApi";
import OverviewTable from "../../../components/OverviewTable/OverviewTable";
import Notitie from "../../Notitie/Notitie";
import Document from "../../Document/Document";

const FietsNotitieDocumentOverview = (({fietsid}) => {

    const api = usePrivateApi();

    return (<>
            <h2>Notities
            </h2>
            <div className="Onderhoud">
                <OverviewTable
                    dataFetchCall={() => api.getNotitiesByFiets(fietsid)}
                    withFilter={false}
                    withSearch={false}
                    headers={[]}
                    mapDataRow={row => (
                        <Notitie context={"klantNotitie"} notitie={row} key={row.onderhoudId}/>
                    )}
                />
            </div>
            <h2>Documenten
            </h2>
            <div className="Onderhoud">
                <OverviewTable
                    dataFetchCall={() => api.getDocumentenByFiets(fietsid)}
                    withFilter={false}
                    withSearch={false}
                    headers={[]}
                    mapDataRow={row => (
                        <Document context={"klantDocument"} document={row} key={row.id}/>
                    )}
                />
            </div>
        </>
    );
})

export default FietsNotitieDocumentOverview;
