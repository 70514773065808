import React, {useState} from "react";
import "./Klant.scss";
import {formatKlantAdres, formatKlantMobile,} from "../../../utils/util";
import StyledSnackbar from "../../../components/StyledSnackbar/StyledSnackbar";

const Klant = (({klant}) => {
    const [message, setMessage] = useState('');

    function navigateToWhatsApp() {
        if (!klant.mobiel || klant.mobiel.length !== 10) {
            setMessage({value: "Ongeldig telefoonnummer", type: "error"});
        } else {
            const mobile = '31' + klant.mobiel.slice(-9);
            window.open(`https://api.whatsapp.com/send?phone=${mobile}`);
        }
    }
    const htmlString = formatKlantMobile(klant);

    return (
        <>
            <tr className={"Klant OnClick"} onClick={navigateToWhatsApp}>
                <td dangerouslySetInnerHTML={{ __html: htmlString }} />
                <td>{formatKlantAdres(klant)}</td>
            { message !== '' && (<td className={"noPadding"}><StyledSnackbar message={message.value} type={message.type} resetMessage={() => setMessage('')}/></td>) }
            </tr>
        </>

    );
});

export default Klant;
